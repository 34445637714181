import axios from "axios";
import {dataLog} from "@/helpers/main";

export default {
  data: () => ({
    loadJWT: false
  }),
  async beforeCreate() {
    try {
      this.loadJWT = false
      const response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/jwt-auth/v1/token/validate',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwt')}`
        },
      });
      dataLog(response.data, 'jwt user is valid');
      this.loadJWT = true
    } catch (error) {
      this.loadJWT = true
      console.error(error);
      this.$store.dispatch('action403', error, {root: true})
    }
  },
  methods:{}
}