<template>
  <header>
    <div class="menu-btn" @click="$store.commit('setMenuFlag', true)">
      <ion-icon name="apps-outline" style="font-size: 22px;"></ion-icon>
    </div>

    <Logo :pData="{type: 'router', url: '/'}" />

    <div class="garage">
      <el-dropdown>
        <img src="../../../assets/image/garage-1.png" alt="">

        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
                v-for="car in cars"
                @click="$store.dispatch('car/setCurrentCar', car.id)"
                :class="{ current: car.id == currentCarID }">{{ car.acf.brand }} {{car.acf.model}}, {{ car.acf.yearOfIssue }}</el-dropdown-item>

            <el-dropdown-item class="add_car" @click="$router.push('/#addCar')" :divided="cars.length">{{ $t('UI.add-car') }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import StatusContant from "@/components/app/organisms/StatusContant";
import {mapActions, mapState} from "vuex";
import Logo from "@/components/app/molecules/Logo";

export default {
  name: "HeaderMobile",
  components: {Logo, StatusContant},
  methods: {
    ...mapActions({
      fetchCars: 'car/fetchCars'
    }),
  },
  computed:{
    ...mapState({
      cars: state => state.car.cars,
      currentCarID: state => state.car.currentCar
    }),
  },
  mounted() {
    if(!this.cars.length > 0) {
      this.fetchCars()
    }
  }
}
</script>

<style lang="scss" scoped>
header{
  display: flex;
  justify-content: space-between;
  .garage{
    img{
      width: 40px;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.mobile-app header{
  .menu-btn{
    background: #EBF0F6;
    border-radius: 3px;
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo{
    display: flex;
    align-items: center;
    flex: 0 0 140px;
    margin: 0;
    width: 35px;
    margin-top: -12px;
    img{
      margin-right: 8px;
      flex: 0;
    }
    span{
      font-weight: 700;
      font-size: 20px;
      font-family: 'Exo 2', sans-serif;
      color: #2c3e50;
    }
  }
  .ua-flag{
    width: 30px;
    margin: -15px 0 0;
  }
  .el-dropdown-menu__item{
    font-size: 12px;
    padding: 4px 14px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover{
      opacity: 0.9;
    }
    &.current{
      font-weight: bold;
    }
    &.add_car{
      padding: 0 14px;
      font-size: 11px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>