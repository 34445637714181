<template>
  <div class="report">
    <header>
      <el-radio-group v-model="sortFlag" size="small" >
        <el-radio-button label="to">{{ $t('pages.report.to') }}</el-radio-button>
        <el-radio-button label="Log">{{ $t('pages.report.Log') }}</el-radio-button>
        <el-radio-button label="toAndLog">{{ $t('pages.report.toAndLog') }}</el-radio-button>
        <el-radio-button label="gasStation">{{ $t('pages.report.gasStation') }}</el-radio-button>
        <el-radio-button label="All">{{ $t('pages.report.all') }}</el-radio-button>
      </el-radio-group>

      <el-tooltip v-if="data.length" class="item" effect="dark" :content="$t('UI.save-pdf')" placement="top-start">
        <span class="pdf-down-btn" @click="exportToPDF">
          <ion-icon name="document-text-outline"></ion-icon>
        </span>
      </el-tooltip>
    </header>

    <el-timeline style="margin-top: 30px">
      <el-timeline-item
          v-if="data.length"
          v-for="(service, index) in sortServices"
          :key="index"
          :type="service.title ? 'primary' : ''"
          :color="!service.title ? '#0bbd87' : ''"
          :hollow="service.title ? true : false"
          :timestamp="service.timestamp">
        <span>{{service.mileage}} {{ $t('UI.statusContent.km') }}</span>
        <span class="divider"></span>
        <div>
          <span><b>{{service.title || $t('pages.report.refueled')}}</b></span>
          <span class="date"><el-icon><Calendar/></el-icon> {{ getDate(service.date ) }}</span> -
          <b style="font-weight: 500">{{service.price}} {{ $t('UI.statusContent.UAH') }}</b>
        </div>
      </el-timeline-item>
      <p v-else>{{ $t('pages.report.no-data') }}</p>
    </el-timeline>
  </div>

  <div v-show="!hidePDFHTML">
    <PDFReport id="pdf-report" >
      <h3>{{ $t('pages.report.title') }} - {{ ReportBy }}</h3>
      <el-timeline style="margin-top: 30px">
        <el-timeline-item
            v-for="(service, index) in sortServices"
            :key="index"
            :type="service.title ? 'primary' : ''"
            :color="!service.title ? '#0bbd87' : ''"
            :hollow="service.title ? true : false"
            :timestamp="service.timestamp">
          <span>{{service.mileage}} {{ $t('UI.statusContent.km') }}</span>
          <span class="divider"></span>
          <div>
            <span><b>{{service.title || $t('pages.report.refueled')}}</b></span>
            <span class="date"><el-icon><Calendar/></el-icon> {{ getDate(service.date ) }}</span> -
            <b style="font-weight: 500">{{service.price}} {{ $t('UI.statusContent.UAH') }}</b>
          </div>
        </el-timeline-item>
      </el-timeline>
    </PDFReport>
  </div>
</template>

<script>
import {mapState} from "vuex";
import html2pdf from "html2pdf.js"
import PDFReport from "@/components/app/molecules/PDFReport";

export default {
  name: "Report",
  components: {PDFReport},
  data: () => ({
    data: [],
    sortFlag: 'to',
    hidePDFHTML: true
  }),
  computed:{
    ...mapState({
      services: state => state.services.allServices,
      fuels: state => state.fuel.fuels,
      car: state => state.car.carObject.acf,
    }),
    sortServices(){
      return this.data.sort((a, b) => a.mileage < b.mileage ? 1 : -1);
    },
    ReportBy(){
      switch (this.sortFlag) {
        case 'to':
          return this.$t('pages.report.to')
        case 'Log':
          return this.$t('pages.report.Log')
        case 'toAndLog':
          return this.$t('pages.report.toAndLog')
        case 'gasStation':
          return this.$t('pages.report.gasStation')
        case 'All':
          return this.$t('pages.report.all-d')
      }
    }
  },
  watch:{
    sortFlag(){
      if(this.sortFlag === 'to') {
        this.data = this.services.filter(i => !i.cat.includes(3))
      }else if(this.sortFlag === 'toAndLog') {
        this.data = this.services
      }else if(this.sortFlag === 'Log'){
          this.data = this.services.filter(i => !i.cat.includes(2))
      } else if(this.sortFlag === 'gasStation'){
        this.data = this.fuels
      }else{
        this.data = []
        this.data = this.data.concat(this.services).concat(this.fuels)
      }
    }
  },
  async created() {
    if(!this.fuels.length > 0) {
      await this.$store.dispatch('fuel/fetchFuels')
    }
    if(!this.services.length > 0) {
      await this.$store.dispatch('services/fetchAllService')
    }

    this.data = this.services.filter(i => !i.cat.includes(3))
  },
  methods:{
    getDate( d ){
      const date = new Date(d);
      return date.toLocaleString('ru', {day: 'numeric', month: 'numeric', year: 'numeric'})
    },
    exportToPDF() {
      html2pdf(document.getElementById("pdf-report"), {
        margin: [8, 15, 8, 8],
        filename: `Carlog-${this.car.brand}-${this.car.model}-${this.car.yearOfIssue}.pdf`,
      }).output('dataurlnewwindow');
    },
  }
}
</script>

<style lang="scss" scoped>
.report {
  header{
    display: flex;
    align-items: center;
  }
  .pdf-down-btn{
    margin-left: 16px;
    background: #ecf0f6;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
  .el-timeline-item{
    padding-bottom: 10px;
  }
  .el-timeline-item__content{
    display: flex;
  }
  ul li:before{
    display: none;
  }
  span{
    display: block;
  }
  .date{
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    .el-icon{
      margin-right: 3px;
    }
  }
}
.divider{
  width: 10px;
  height: 1.5px;
  background: #0B626A;
  margin: 7px 10px 0;
}
</style>

<style lang="scss">
.report {
  .el-timeline-item__content {
    display: flex;
  }
  @media screen and (max-width: 425px) {
    .el-timeline{
      padding-left: 0;
    }
    .el-timeline-item__wrapper{
      padding-left: 18px;
    }
    .el-timeline-item__content{
      span:first-child{
        font-size: 11px;
        margin-top: 2px;
      }
    }
    .divider{
      width: 6px;
      height: 1.1px;
      margin: 9px 5px 0;
    }
    .el-radio-group .el-radio-button__inner{
      font-size: 11px;
      padding: 5px 10px;
    }
  }
  @media screen and (max-width: 375px) {
    .el-radio-group .el-radio-button__inner{
      font-size: 10px;
      padding: 5px 8px;
    }
  }
}
</style>