<template>
  <div class="main_loader" v-loading="true"></div>
</template>

<script>
export default {
  name: "MainLoader"
}
</script>

<style scoped>
.main_loader{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>