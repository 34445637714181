<template>
  <div class="fuel_wrap" v-if="hideCounter === false">
    <el-tooltip class="item" effect="dark" :content="$t('UI.statusContent.tooltip-fuel')" placement="top-start">
      <img src="../../../assets/image/jerrycan.png" alt="" class="jerrycan">
    </el-tooltip>
    <div class="text">
      <span>TOTAL: <b>{{ price }}{{ $t('UI.statusContent.UAH') }}</b> - {{ kilomets }}{{ $t('UI.statusContent.km') }}</span>
      <div>
        <span>{{ priceOfKillomets }}{{ $t('UI.statusContent.UAH/km') }}</span>
        <span>{{ consumption }} {{ $t('UI.statusContent.l/100km') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "StatusFuel",
  data: () => ({
    fuelsArr: [],
    liters: 0,
    price: 0,
    kilomets: 0,
    mileagePointStart: null,
    mileagePointEnd: false,
    hideCounter: true
  }),
  computed:{
    ...mapState({
      fuels: state => state.fuel.fuels,
      start_point_fuel: state => state.fuel.start_point_fuel,
      currentCar: state => state.car.currentCar,
    }),

    consumption(){
      if(this.liters == 0) return 0
      const a = this.liters / this.kilomets * 100
      return a.toFixed(2)
    },
    priceOfKillomets(){
      if(this.price == 0) return 0
      const b = this.price / this.kilomets
      return b.toFixed(2)
    }
  },
  watch:{
    fuels: {
      handler(){
        this.resetVar()
        this.count()
      },
      deep: true
    },
    start_point_fuel(){
      this.resetVar()
      this.count()
    },
    currentCar(){
      this.fetchFuel()
    }
  },
  mounted() {
    if(!this.fuels.length) {
      this.fetchFuel()
    }else{
      this.resetVar()
      this.count()
    }
  },
  methods:{
    ...mapActions({
      fetchFuel: 'fuel/fetchFuels',
    }),
    count(){
      //if(!Object.keys(this.fuels).length){
      if(this.fuels.length <= 0) return false
      const lastPoint = this.fuels.findIndex(i => i.fullTank === true)

      //откинул заправки не до полного снизу и сверху массива
      if(this.start_point_fuel.length){
        const point = this.fuels.findIndex(i => i.id == this.start_point_fuel)
        this.fuelsArr = this.fuels.filter((e, i) => i < point).filter((e, i) => i >= lastPoint)
        this.mileagePointStart = point
      }else{
        const point = this.fuels.findLastIndex(i => i.fullTank === true)
        this.fuelsArr = this.fuels.filter((e, i) => i < point).filter((e, i) => i >= lastPoint)
        this.mileagePointStart = point
      }
      if(!this.fuelsArr.length) return false

      //fuelsArr - польностью отфильтрован
      //запись верхней и нижней границы пробега
      this.mileagePointStart = this.fuels[this.mileagePointStart].mileage_f
      this.mileagePointEnd = this.fuelsArr.at(0).mileage_f

      this.fuelsArr.map((i) => {
        this.liters += i.liters
        this.price += i.price
      })

      this.price = this.price.toFixed(2)
      this.kilomets = this.mileagePointEnd - this.mileagePointStart

      this.hideCounter = false
    },
    resetVar(){
      this.fuelsArr = []
      this.liters = 0
      this.price = 0
      this.kilomets = 0
      this.mileagePointStart = null
      this.mileagePointEnd = false
      this.hideCounter = true
    }
  }
}
</script>

<style lang="scss" scoped></style>