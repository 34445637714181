<template>
  <div class="demo-image__preview">
    <el-image
        v-for="(img, index) in srcList"
        :key="img"
        :src="img"
        :preview-src-list="srcList"
        :initial-index="index"
        fit="cover"
        class="img_car"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Gallery",
  data: () => ({
    images: []
  }),
  computed:{
    ...mapState({
      srcList: state => state.car.carPhoto.urls
    })
  },
}
</script>

<style lang="scss" scoped>
  .demo-image__preview{
    margin: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 550px) {
      .img_car{
        height: 110px;
      }
    }
  }
  .img_car{
    max-width: 200px;
    flex: 1 1 calc(50% - 10px);
    height: 130px;
    margin: 0 5px 10px;
    border-radius: 5px;
    position: relative;
    transition: 0.3s ease;
  }
  .demo-image__error .image-slot {
    font-size: 30px;
  }
  .demo-image__error .image-slot .el-icon {
    font-size: 30px;
  }
  .demo-image__error .el-image {
    width: 100%;
    height: 200px;
  }
</style>