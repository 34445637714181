import firebase from 'firebase/compat/app';
import router from '@/router'
import {dataLog} from "@/helpers/main";

export const authPhone = {
	state: () => ({
    loading: false,
    confirmationResult: null,
		recaptchaVerifier: null,
		recaptchaWidgetId: null,
		confirmResult: null,
		smsSentStatus: false,
		failCodeStatus: false,
	}),
	getters: {},
	mutations: {
		setCode(state, code) {
			state.code = code;
		},
    resetState(state){
      state.confirmationResult = null
      state.recaptchaVerifier = null
      state.recaptchaWidgetId = null
      state.confirmResult = null
      state.smsSentStatus = false
      state.failCodeStatus = false
    }
	},
	actions: {
		async login({state, commit, dispatch}, phone) {
      //double recaptcha fix
      if(state.recaptchaVerifier !== null) return false
			state.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
			state.recaptchaVerifier.render().then((widgetId)=>{
				state.recaptchaWidgetId = widgetId
			})

			await firebase.auth().signInWithPhoneNumber(phone, state.recaptchaVerifier)
			.then((confirmationResult)=>{                
				state.confirmResult = confirmationResult
        dataLog("Sms Sent")
				state.smsSentStatus = true
        state.recaptchaVerifier = null
        state.recaptchaWidgetId = null
			})
			.catch((error)=>{
				console.log("Sms - not sent",error.message)
        commit('setMessage', {type: 'error', text: 'Oh, SMS - not send: ' + error.message}, {root: true})
        dispatch('logout')
			})
		},

		async sendCode({state, commit, dispatch}, code){
			state.failCodeStatus = false
      state.loading = true
      await state.confirmResult.confirm(code)
			.then(async (result)=>{
        const info = await dispatch('info/fetchInfo', null, {root:true})
        dataLog("Login - success", info)

        //if user firebase - empty - go to registration
        if(info){
          commit('setCurrentUserID',  info.userIDWP, {root: true})
          await dispatch('auth/loginWPInit', {email: info.email, password: info.pass}, {root:true})
        }else{
          router.replace({name:"Registration"})
        }
        state.smsSentStatus = false
      })
			.catch((error)=>{
        state.failCodeStatus = true
				console.log(error)
        commit('setMessage', {type: 'error', text: 'Oh, code is wrong: ' + error.message}, {root: true})
        return false
			})
      state.loading = false
    },

    async logout({dispatch}){
      await firebase.auth().signOut()
      dispatch('resetStoreData', true, {root: true})
    }
	},
	namespaced: true
}
