<template>
  <el-button
      type="success"
      @click="dialogFormVisible = true"
      class="btn-opacity add-comp-btn el-button-mob"
      style="padding-left: 7px; font-size: 13px; background: #2BC8A1; border: none;">
    <el-icon style="margin-right: 5px"><CirclePlusFilled /></el-icon>
    {{$t('UI.buttons.add')}}
  </el-button>

  <el-dialog :title="$t('pages.components.add-title')"  v-model="dialogFormVisible" style="max-width: 390px; width: 95%;">
    <el-form :model="newComponent" :rules="rules" ref="newComponent" class="add-comp-form"  @submit.prevent>
      <el-form-item :label="$t('pages.components.form.name')" prop="name">
        <el-input v-model="newComponent.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('pages.components.form.brand')" prop="brand">
        <el-input v-model="newComponent.brand"></el-input>
      </el-form-item>
      <el-form-item :label="$t('pages.components.form.art')" prop="code">
        <el-input v-model="newComponent.code"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('newComponent')">{{$t('UI.buttons.cancel')}}</el-button>
      <el-button type="primary" @click="submitForm('newComponent')">{{$t('UI.buttons.add')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {

  },
  name: "AddComponents",
  data(){
    return{
      dialogFormVisible: false,
      newComponent: {
        id: '',
        name: '',
        brand: '',
        code: '',
      },
      rules: {
        name: [
          { required: true, message: this.$t('pages.components.form.rules.name'), trigger: 'blur' },
          { min: 3, max: 24, message: this.$t('pages.components.form.rules.name-m'), trigger: 'blur' }
        ],
        brand: [
          { required: true, message: this.$t('pages.components.form.rules.brand'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.components.form.rules.brand-m'), trigger: 'blur' }
        ],
        code: [
          { required: true, message: this.$t('pages.components.form.rules.art'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.components.form.rules.art-m'), trigger: 'blur' }
        ],
      }
    }
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.newComponent.id = uuidv4()
          this.$emit('add', this.newComponent)

          this.dialogFormVisible = false
          this.newComponent = {
            id: '',
            name: '',
            brand: '',
            code: '',
          }
        } else {
          this.$message.error('Заполните правильно форму!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
.add-comp-form{
  .el-form-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.el-dialog{
  max-width: 320px;
}
</style>

<style lang="scss">
.add-comp-form{
  .el-form-item__content{
    width: 100%;
    flex: auto;
  }
  .el-form-item__error{
    font-size: 11px;
  }
  .el-form-item__label{
    height: 25px;
    display: flex;
    flex-direction: row-reverse;
    &:before{
      margin-left: 5px;
    }
  }
}
</style>

<style lang="scss">
.mobile-app{}
.main{
  @media screen and (max-width: 1200px) {
    .components, .components_wrap{
      padding-right: 0 !important;
    }
    h3{
      padding: 12px 0;
    }
    .add-comp-btn{
      position: absolute;
      top: 24px;
    }
    .todo-comp{
      position: relative;
      .el-button.el-button-mob{
        font-size: 0 !important;
        padding: 0 8px;
        .el-icon{
          font-size: 13px !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}
</style>