<template>
  <div>
    <h1>{{ title }}
      <el-button class="btn-cust" style="background: #3C5B82; color: #fff" @click="$store.commit('services/changeOpenAddForm')" circle>
        <el-icon>
          <CirclePlusFilled/>
        </el-icon>
      </el-button>
    </h1>
    <p>{{description}}</p>

    <div class="wrap-box-card">
      <el-card
          v-for="ser in services"
          :key = ser.id
          class="box-card">
        <div slot="header" class="box-card-header">
          <span class="title">{{ ser.title }}</span>
          <div>
            <span class="mileage"><el-icon><Guide/></el-icon> {{ ser.mileage }} {{ $t('UI.statusContent.km') }}</span>
            <span class="date"><el-icon><Calendar/></el-icon> {{ getDate(ser.date ) }}</span>
          </div>
        </div>

        <div class="cont" v-html="ser.description "></div>
        <div class="box-card-footer">
          <span class="price">
            <el-icon><CreditCard /></el-icon>
            {{ ser.price }} {{$t('UI.statusContent.UAH')}}
          </span>
          <div>
            <el-link v-if="futureSevice === 'true'" type="primary" style="color: #3C5D82" :underline="false" @click="moveConfirm(ser)">{{$t('UI.links.complete')}}</el-link>
            <el-link type="primary" style="color: #3C5D82" :underline="false" @click="$emit('edit', ser)">{{$t('UI.links.edit')}}</el-link>
            <el-link type="danger" style="color: #3C5D82" :underline="false" @click="removeConfirm(ser)">{{$t('UI.links.delete')}}</el-link>
          </div>
        </div>
      </el-card>

      <p v-if="!!services">{{ $t('pages.logbook.no-note') }}</p>
    </div>

    <el-dialog
        :title="$t('pages.logbook.move-logbook')"
        v-if="futureSevice === 'true'"
        v-model="dialogVisible"
        class="logbook-dialog"
        width="380px">
      <span>{{movePost.title}}</span>

      <el-radio-group v-model="catID">
        <el-radio :label="3">{{ $t('UI.sidebar.logbook') }}</el-radio>
        <el-radio :label="2">{{ $t('UI.sidebar.maintenance') }}</el-radio>
      </el-radio-group>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{ $t('UI.links.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="movePostMethod">{{ $t('UI.links.move') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ListLogbook",
  data: () => ({
    dialogVisible: false,
    movePost: null,
    catID: 3
  }),
  props: {
    title: String,
    description: String,
    futureSevice: String,
    services: {
      type: Object,
      required: true
    }
  },
  methods:{
    getDate( d ){
      const date = new Date(d);
      return date.toLocaleString('ru', {day: 'numeric', month: 'numeric', year: 'numeric'})
    },
    moveConfirm(post){
      this.catID = 3  //reset catID
      this.movePost = post
      this.dialogVisible = true
    },
    movePostMethod(){
      this.$store.commit('services/setServices', this.services.filter( s => s.id !== this.movePost.id))

      if(this.catID == 3){
        this.$store.dispatch('services/moveService', {service: this.movePost, catID: this.catID, mess: this.$t('pages.logbook.notification.fuel-move-logbook')})
      }else{
        this.$store.dispatch('services/moveService', {service: this.movePost, catID: this.catID, mess: this.$t('pages.logbook.notification.fuel-move')})
      }
      this.dialogVisible = false
    },
    removeConfirm(post) {
      this.$confirm(this.$t('pages.logbook.notification.fuel-delete.ask') + post.title + '?', 'Warning', {
        confirmButtonText: this.$t('pages.logbook.notification.fuel-delete.delete'),
        cancelButtonText: this.$t('pages.logbook.notification.fuel-delete.cancel'),
        type: 'warning'
      }).then(() => {
        //remove for state services
        this.$store.commit('services/setServices', this.services.filter( s => s.id !== post.id))

        this.$store.dispatch('services/removeService', post)
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Delete error'
        // });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  justify-content: space-between;
  max-width: 950px;
}
p{
  max-width: 950px;
}
.mileage, .date {
  font-size: 13px;
  font-weight: bold;
  display: flex;

  .el-icon {
    font-size: 16px;
    color: #3C5B82;
    margin-right: 3px;
  }
}

span.title {
  flex: 0 0 50%;
  padding-right: 10px;
  line-height: 15px;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-weight: bold;
  color: #070c5b;
}

.date {
  .el-icon {
    color: #3C5B82;
  }
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 10px;
}

.box-card {
  flex: 0 1 calc(50% - 10px);
  margin: 10px 0;
  position: relative;
}

.box-card-header {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  >div{
    display: flex;
    .mileage{
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.wrap-box-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 950px;
}
.box-card-footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 10px 10px;
  box-sizing: border-box;
  .price{
    display: flex;
    align-items: center;
    font-weight: bold;
    .el-icon{
      font-size: 18px;
      color: green;
      margin-right: 3px;
    }
  }
  .el-link {
    font-weight: bold;
    font-size: 12px;
    padding: 0 10px;
  }
}
</style>

<style lang="scss">
.el-card__body{
  padding-bottom: 40px !important;
  @media screen and (max-width: 768px) {
    padding: 15px 15px 44px !important;
  }
}
.cont {
  font-size: 13px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  ol {
    padding-left: 12px;
    margin-top: 6px;
  }
  ul {
    list-style: none;
    padding-left: 12px;
    margin-top: 6px;
    font-size: 12px;
    li {
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: -11px;
        top: -5px;
        font-size: 18px;
      }
    }
  }
}
.logbook-dialog{
  .el-dialog__body{
    padding: 0 22px 20px;
    &>span{
      font-weight: bold;
      display: block;
      margin-top: 5px;
    }
    .el-radio-group{
      margin: 6px 0 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
.main{
  @media screen and (max-width: 1200px) {
    .box-card {
      flex: 100%;
    }
    .el-card__body {
      padding: 15px 15px 44px !important;
    }
    .box-card-header > div{
      flex-direction: column;
      span{
        margin-right: 0;
        font-size: 11px;
        .el-icon{
          font-size: 13px;
        }
      }
    }
    .el-button+.el-button {
      margin-left: 0;
      margin-top: 5px;
    }
    .button_wrap{
      display: flex;
      flex-direction: column;
      padding-left: 6px;
    }
  }
}
</style>