import ru from './RU-ru.json'
import en from './EN-en.json'
import ua from './UA-ua.json'

import {createI18n} from "vue-i18n";

//localStorage.setItem('i18nLanguage', 'en');

const i18nLanguage = localStorage.getItem('i18nLanguage');

export const defaultLocale = i18nLanguage ? i18nLanguage : 'ua'
export const languages = {
  en,
  ru,
  ua
}

const messages = Object.assign(languages)
export default createI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages
})