<template>
  <div style="padding: 0 15px">
    <h1>{{ $t('pages.settings.title') }}</h1>

    <el-form :model="profile" :rules="rules" ref="profile" label-width="120px" class="demo-profile" label-position="top" style="max-width: 420px">
      <div class="col-2">
        <el-form-item :label="$t('pages.settings.form.name')" prop="name">
          <el-input v-model="profile.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.settings.form.phone')" prop="phone">
          <el-input v-model="profile.phone" :disabled="true"></el-input>
        </el-form-item>
      </div>

      <el-form-item label="Email" prop="email">
        <el-input v-model="profile.email" :disabled="true"></el-input>
      </el-form-item>
      <el-switch
          v-model="language"
          active-color="#02B7BB"
          inactive-color="#91C5FF"
          active-text="Українська"
          inactive-text="English"
          size="small"
          style="margin:0 0 10px"
      >
      </el-switch>


      <el-form-item>
        <el-button type="primary" @click="submitForm('profile')" size="small">{{ $t('UI.buttons.save') }}</el-button>
      </el-form-item>

      <!--      <el-select v-model="currencyactive" placeholder="Select">-->
      <!--        <el-option-->
      <!--            v-for="item in currency"-->
      <!--            :key="item.value"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value">-->
      <!--        </el-option>-->
      <!--      </el-select>-->
    </el-form>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Settings.vue",
  data() {
    return {
      // currencyactive: 'UAH',
      // currency: [
      //   {value: 'UAH', label: 'UAH' },
      //   {value: 'USD', label: 'USD' },
      //   {value: 'EUR', label: 'EUR' },
      // ],
      language: true,
      profile: {
        name: '',
        phone: '',
        email: '',
        lang: 'ua',
      },
      rules: {
        name: [
          { required: true, message: this.$t('pages.settings.form.rules.name'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.settings.form.rules.name-m'), trigger: 'blur' }
        ],
        phone: [
          { required: true, message: this.$t('pages.settings.form.rules.phone'), trigger: 'blur' },
        ],
        email: [
          { required: true, message: this.$t('pages.settings.form.rules.email'), trigger: 'blur',},
          { type: 'email', message: this.$t('pages.settings.form.rules.email-m'), trigger: ['blur', 'change'],},
        ],
      }
    };
  },
  computed:{
    ...mapState({
      info: state => state.info.info,
    }),
  },
  watch:{
    language(){
      this.profile.lang = this.language ? 'ua' : 'en'
    }
  },
  methods: {
    ...mapActions({
      updateInfo: 'info/updateInfo'
    }),
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.$store.dispatch('info/updateInfo', this.profile)
          window.location.replace(window.location.href);
          //this.$store.dispatch('info/getUid')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted() {
    this.profile.name = this.info.name
    this.profile.phone = this.info.phone
    this.profile.email = this.info.email
    this.profile.lang = this.info.lang

    this.language = ((this.profile.lang === 'ua') ? true : false);
  }
}
</script>

<style lang="scss">
  .el-switch__label.is-active{
    span{
      color: #029598;
    }
  }
</style>