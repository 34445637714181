<template>
	<div v-if="loadApp === true && loadJWT === true" class="wrapper" :class="classMobile">
		<div class="main">
      <Header v-if="!$isMobile()"  />
      <HeaderMobile v-else/>
			<div class="cont">
        <Sidebar />
        <div class="content">
          <router-view />

          <DrawerBottom v-if="this.$isMobile()" />
        </div>
      </div>
    </div>

    <Footer/>
	</div>

  <MainLoader v-else/>
</template>

<script>
import Sidebar from '@/components/app/organisms/Sidebar'
import Header from '@/components/app/Header'
import {mapState} from "vuex";
import MainLoader from "@/components/app/atoms/MainLoader";
import HeaderMobile from "@/components/app/mobile/HeaderMobile";
import DrawerBottom from "@/components/app/organisms/DrawerBottom";
import Footer from "@/components/app/Footer";
import checkJwt from "@/mixins/check-jwt";

export default {
  data: () => ({
    loadApp: false
  }),
  mixins: [checkJwt],
  computed: {
    ...mapState({
      car: state => state.car.currentCar,
      info: state => state.info.info
    }),
    classMobile(){
      return this.$isMobile() ? 'mobile-app' : 'desktop-app'
    }
  },
  methods: {
    // ...mapActions({
    //   jwtLogin: 'jwtLogin',
    //   fetchCar: 'car/getCurrentCar'
    // }),
  },
  async mounted() {
    //await this.jwtLogin()
    //await this.fetchCar()

    if(!Object.keys(this.info).length){
      await this.$store.dispatch('info/fetchInfo')
    }
    if(this.car){
      await this.$store.dispatch('car/fetchCar', this.car)
    }
    this.loadApp = true
  },
  components:{
    Footer,
    DrawerBottom,
    HeaderMobile,
    MainLoader,
    Header,
    Sidebar
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
  flex-direction: column;
	background: #d4e8ea;
	width: 100%;
	height: 100vh;
	.main{
		max-width: 1220px;
		background: #fff;
		border-radius: 20px;
		padding: 30px 20px;
		width: 95%;
    height: 90%;
    max-height: 700px;
		box-shadow: 0px 10px 20px #00000017;
    overflow: hidden;
    .cont{
      display: flex;
      height: calc(100% - 55px);
    }
		header{
      margin-bottom: 15px;
      height: 55px;
		}
		sidebar{
			flex: 0 1 250px;
			margin-right: 25px;
      height: 100%;
		}
    .content{
      flex: auto;
      text-align: left;
      height: calc(100% - 15px);
      overflow-y: scroll;
      padding-right: 15px;
    }
	}
}
</style>

<style lang="scss" scoped>
.mobile-app{
  background: #ffffff;
  justify-content: flex-start;
  height: inherit;
  .wrapper{
    height: auto;
  }
  .main{
    width: 100%;
    //height: 100vh;
    max-height: 100%;
    border-radius: 0;
    padding-top: 15px;
    overflow: inherit;
    .content{
      padding-right: 0;
      height: 100%;
      overflow-y: inherit;
    }
    .cont{
      height: auto;
    }
    header{

    }
    sidebar{

    }
    .content{

    }
  }
}
</style>
