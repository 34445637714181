<template>
<div class="report-pdf">
  <div style="margin-bottom: 10px; font-size: 12px;">
    <a :href="config.url" style="font-size: 12px; color: #0a4b78">{{ config.url }}</a>
    - {{ $t('tag-line') }}
  </div>
  <header>
    <Logo :pData="{type: 'link', url: config.url}" />

    <div style="display: flex; justify-content: space-between; flex: auto">
      <h2>{{ title }} <span>{{ car.acf.mileage }} {{ $t('UI.statusContent.km') }}</span></h2>
      <span class="vin" style="cursor: pointer">{{ car.acf.vin }}</span>
    </div>
  </header>
  <slot/>

  <footer>
    <p>{{ $t('pages.report.rep-desc-1')}} <a :href="config.url" style="color: #0A246A">{{ config.domain }}</a></p>
    <p>{{ $t('pages.report.rep-desc-2')}}</p>
  </footer>
</div>
</template>

<script>
import {mapState} from "vuex";
import Logo from "@/components/app/molecules/Logo";

export default {
  name: "PDFReport",
  components: {Logo},
  computed:{
    ...mapState({
      car: state => state.car.carObject
    }),
    title(){
      return `${this.car.acf.brand} ${this.car.acf.model}, ${this.car.acf.yearOfIssue}`
    },
    config(){
      return {
        "domain": process.env.VUE_APP_DOMAIN,
        "url": process.env.VUE_APP_DOMAIN_URL,
      }
    }
  }
}
</script>

<style scoped lang="scss">
.report-pdf {
  position: relative;
  background: #fdfbfb;
  padding: 25px;
  header{
    border-bottom: 3px solid #6ECCCF;
    display: flex;
    padding: 10px 0;
    h2{
      margin: 5px 0;
      font-size: 19px;
      span{
        font-size: 12px;
        display: block;
      }
    }
  }
  .logo{
    margin-top: 0;
  }
  .vin{
    font-size: 12px;
    font-weight: bold;
    position: relative;
    margin-top: 10px;
    &:before{
      content: "VIN";
      font-size: 9px;
      color: #ff2525;
      position: absolute;
      left: -33px;
      top: -2px;
      border: 2px solid #FF2525;
      border-radius: 5px;
      padding: 1px 3px;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss">
.report-pdf {
  //transform: scale(0.92);
  h3{
    padding-left: 12px;
  }
  .el-timeline{
    padding-left: 0;
  }
  .el-timeline-item {
    padding-bottom: 10px;
  }
  .el-timeline-item__content {
    display: flex;
  }
  .el-timeline-item__content{
    display: flex;
  }
  ul li:before{
    display: none;
  }
  span{
    display: block;
  }
  .date{
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    .el-icon{
      margin-right: 3px;
    }
  }
  .divider{
    width: 10px;
    height: 1.5px;
    background: #0B626A;
    margin: 7px 10px 0;
  }
}
</style>