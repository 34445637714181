<template>
  <el-dialog :title="$t('pages.components.edit-title')"  v-model="dialogFormVisible" style="max-width: 390px; width: 95%;">
    <el-form :model="component" :rules="rules" ref="editComp" class="add-comp-form"  @submit.prevent>
      <el-form-item :label="$t('pages.components.form.name')" prop="name">
        <el-input v-model="component.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('pages.components.form.brand')" prop="brand">
        <el-input v-model="component.brand"></el-input>
      </el-form-item>
      <el-form-item :label="$t('pages.components.form.art')" prop="code">
        <el-input v-model="component.code"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('editComp')">{{$t('UI.buttons.cancel')}}</el-button>
      <el-button type="primary" @click="submitForm('editComp')">{{$t('UI.buttons.edit')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    editComp:{
      type: Object,
      required: true
    }
  },
  name: "EditComponents.vue",
  data(){
    return{
      dialogFormVisible: false,
      component: '',
      rules: {
        name: [
          { required: true, message: this.$t('pages.components.form.rules.name'), trigger: 'blur' },
          { min: 3, max: 24, message: this.$t('pages.components.form.rules.name-m'), trigger: 'blur' }
        ],
        brand: [
          { required: true, message: this.$t('pages.components.form.rules.brand'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.components.form.rules.brand-m'), trigger: 'blur' }
        ],
        code: [
          { required: true, message: this.$t('pages.components.form.rules.art'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.components.form.rules.art-m'), trigger: 'blur' }
        ],
      }
    }
  },
  watch:{
    editComp: function (){
      this.dialogFormVisible = this.editComp.dialogVisible
      this.component = this.editComp.component
    },
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('change', this.component)

          this.dialogFormVisible = false
        } else {
          this.$message.error('Извините, заполните все поля!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.dialogFormVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-comp-form{
  .el-form-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.el-dialog{
  max-width: 320px;
}
</style>

<style lang="scss">
.add-comp-form{
  .el-form-item__content{
    width: 100%;
    flex: auto;
  }
  .el-form-item__error{
    font-size: 11px;
  }
  .el-form-item__label{
    height: 25px;
    display: flex;
    flex-direction: row-reverse;
    &:before{
      margin-left: 5px;
    }
  }
}

</style>