<template>
  <div class="components_wrap">
    <h3>{{ $t('pages.components.list-title') }}</h3>
    <div
        v-if="components.length"
        class="item"
        v-for="item in components"
        :key="item.id">
      <div>
        {{item.name}}:
        <strong>{{item.brand}} {{item.code}}</strong>
      </div>
      <div class="button_wrap">
        <el-button
            type="primary"
            :icon="Edit"
            @click="$emit('open', item)"
            class="btn-opacity el-button-mob"
            size="small"
            style="background: #0082A3; border: none">
          <el-icon><EditPen /></el-icon> {{$t('UI.buttons.edit')}}
        </el-button>

        <message-confirm :component="item" @remove="$emit('remove', item)"  />
      </div>
    </div>
    <p v-else>{{ $t('pages.components.no-components') }}</p>
  </div>
</template>

<script>
import MessageConfirm from "@/components/app/UI/MessageConfirm";
export default {
  name: 'ListComponents',
  components: {MessageConfirm},
  props:{
    components: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    editComp(){

    }
  }),
  methods:{

  }
}
</script>

<style lang="scss" scoped>
h3{
  border-top: 1px solid;
  font-size: 17px;
  padding-top: 5px;
  margin-bottom: 5px;
}
.components{
  //padding-right: 15px;
}
.components_wrap{
  padding-right: 20px;
  flex: auto;
  .item{
    font-family: Helvetica, Arial, sans-serif;
    border-bottom: 1px solid #eeeeee;
    padding: 7px 0;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.main{
  @media screen and (max-width: 768px) {
    .el-button+.el-button {
      margin-left: 0;
      margin-top: 5px;
    }
    .button_wrap{
      display: flex;
      flex-direction: column;
      padding-left: 6px;
    }
  }
}
</style>