<template>
  <router-link v-if="pData.type == 'router'" :to="pData.url" class="logo">
    <img src="../../../assets/image/logo-new.png" alt="" class="logo">
    <span>{{domain}}</span>
    <img src="../../../assets/image/ua-flag.png" alt="" class="ua-flag">
  </router-link>

  <a v-else-if="pData.type == 'link'" :href="pData.url" class="logo">
    <img src="../../../assets/image/logo-new.png" alt="" class="logo">
    <span>{{domain}}</span>
    <img src="../../../assets/image/ua-flag.png" alt="" class="ua-flag">
  </a>

  <div v-else class="logo">
    <img src="../../../assets/image/logo-new.png" alt="" class="logo">
    <span>{{domain}}</span>
    <img src="../../../assets/image/ua-flag.png" alt="" class="ua-flag">
  </div>
</template>

<script>
export default {
  name: "Logo",
  props:{
    pData: {
      type: Object,
      required: true
    }
  },
  computed:{
    domain(){
      return process.env.VUE_APP_DOMAIN
    },
    logoContent(){
      return  '<img src="src/assets/image/logo-new.png" alt="" class="logo">\n' +
              '<span>CarLog</span>\n' +
              '<img src="../../../assets/image/ua-flag.png" alt="" class="ua-flag">'
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{
  display: flex;
  align-items: center;
  flex: 0 0 185px;
  margin: 0 25px 0 0;
  width: 35px;
  margin-top: -12px;
  img{
    margin-right: 8px;
    flex: 0;
  }
  span{
    font-weight: 700;
    font-size: 20px;
    font-family: 'Exo 2', sans-serif;
    color: #2c3e50;
  }
}
.ua-flag{
  width: 30px;
  margin: -15px 0 0;
}
</style>