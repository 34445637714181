import axios from "axios";
import firebase from 'firebase/compat/app';
import router from '@/router'
import {dataLog} from "@/helpers/main";

export const auth = {
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    async registerInit({dispatch}, {email, password, name, lang}){
      //hash password
      const hash = await dispatch('hashPassword', {pass: password, encrypt: true})

      await dispatch('register', {email, password: hash, name, lang})
      const reg = await dispatch('registerWP', {email, password, username: name})
      if(reg){
        const userWpStatus = await dispatch('loginWP', {email, password: hash})
        if(userWpStatus === true){
          router.replace({name:"home"})
        }
      }
    },

    async loginWPInit({dispatch}, {email, password}){
      const userWpStatus = await dispatch('loginWP', {email, password})
      //await dispatch('info/getUidWP', null, {root: true})

      if(userWpStatus === true){
        router.replace({name:"home"})
      }
    },

    async register({dispatch, commit}, {email, password, name, lang}) {
      try {
        //await firebase.auth().createUserWithEmailAndPassword(email, password)
        const uid = await dispatch('info/getUid', null, {root:true})
        const phone = firebase.auth().currentUser.phoneNumber
        await firebase.database().ref(`/users/${uid}/info`).set({
          name: name,
          email: email,
          pass: password,
          lang: lang,
          phone
        })
        commit('info/setInfo', {email, password, name, phone}, {root: true})
      } catch (error) {
        console.log(error)
        commit('setMessage', {type: 'error', text: 'Oh, register error: ' + error.message}, {root: true})
        throw error
      }
    },

    async loginWP({dispatch, commit}, {email, password}) {
      try {
        const pass = await dispatch('hashPassword', {pass: password, encrypt: false})
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/jwt-auth/v1/token',
          data: {
            username: email,
            password: pass
          },
        });
        localStorage.setItem('jwt', response.data.token)
        dataLog(response.data, 'jwt');
        return true
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async registerWP({dispatch, commit}, {username, email, password}){
      try{
        const userWP = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/users/register',
          data:{
            username: username,
            email: email,
            password: password
          }
        })
        const userID = userWP.data.user.userID
        await dispatch('info/updateInfo', {userIDWP: userID}, {root:true})
        await commit('setCurrentUserID',  userID, {root: true})

        dataLog(userWP, 'userWP')
        return true
      } catch (error){
        console.error(error)
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async checkEmail({dispatch}, email) {
      const usersRef = firebase.database().ref('users');
      let emailIsFind = false

      await usersRef.once('value', function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          // Получение доступа к таблице 'info' каждого пользователя
          let infoRef = childSnapshot.child('info');

          // Проверка значения поля 'email'
          let emailF = infoRef.child('email').val();
          if (emailF === email) {
            console.log('Email found in database!');
            emailIsFind = true
          }else{
            console.log('Email not found in database!');
          }
        });
      });
      return emailIsFind
    },

    async hashPassword({dispatch}, {pass, encrypt}){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/hash-pass',
          data: {
            hash: pass,
            encrypt: encrypt
          }
        });
        return response.data.pass
      } catch (error) {
        console.error(error, "Error, hash password");
      }
    }
  },
  namespaced: true
}