<template>
  <div>
    <h1>{{title}}</h1>

    <did v-if="!cars.length" v-html="$t('pages.main.no-car')"></did>

    <p v-if="carEdit">{{$t('pages.main.edit-car') }}</p>

    <el-form
        :model="car"
        :rules="rules"
        ref="car"
        label-width="120px"
        class="demo-ruleForm"
        label-position="top"
        style="max-width: 650px">
      <div class="col-2">
        <el-form-item :label="$t('pages.main.form.brand')" prop="brand" >
          <el-input v-model="car.brand"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pages.main.form.model')" prop="model">
          <el-input v-model="car.model"></el-input>
        </el-form-item>
      </div>

      <div class="col-2">
        <el-form-item label="VIN" prop="vin">
          <el-input v-model="car.vin"></el-input>
        </el-form-item>

        <el-form-item :label="$t('pages.main.form.year')" required>
          <el-form-item prop="yearOfIssue">
            <el-date-picker
                v-model="car.yearOfIssue"
                type="year"
                :placeholder="$t('pages.main.form.choose-year')">
            </el-date-picker>
          </el-form-item>
        </el-form-item>
      </div>

      <el-form-item :label="$t('pages.main.form.fuels.title')" prop="fuel">
        <el-radio-group v-model="car.fuel">
          <el-radio :label="$t('pages.main.form.fuels.gas')"></el-radio>
          <el-radio :label="$t('pages.main.form.fuels.diesel')"></el-radio>
          <el-radio :label="$t('pages.main.form.fuels.lpg')"></el-radio>
          <el-radio :label="$t('pages.main.form.fuels.hybrid')"></el-radio>
          <el-radio :label="$t('pages.main.form.fuels.electric')"></el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="col-2 width-auto">
        <el-form-item :label="$t('pages.main.form.cur-millage')" prop="mileage">
          <el-input-number
            controls-position="right"
            v-model="car.mileage"
            :min="1"
            :max="1000000"
            style="margin-right: 10px">
          </el-input-number> {{ $t('UI.statusContent.km') }}.
        </el-form-item>

        <el-form-item :label="$t('pages.main.form.buy-millage')" prop="mileageBuy">
          <el-input-number
              controls-position="right"
              v-model="car.mileageBuy"
              :min="1"
              :max="1000000"
              style="margin-right: 10px">
          </el-input-number> {{ $t('UI.statusContent.km') }}.
        </el-form-item>
      </div>

      <el-form-item class="wysiwyg-quill" :label="$t('pages.main.form.desc')" prop="aboutCar">
        <quill-editor
            ref="aboutCarEditor"
            v-model:content="car.aboutCar"
            contentType="html"
            toolbar="essential"/>
      </el-form-item>

      <!-- Edit image block-->
      <span
        class="wrap_image"
        v-if="carEdit && editPhoto.length"
        v-for="img in editPhoto"
        :key="img.id"
      >
        <el-image
          style="width: 100px; height: 100px; margin: 0 5px"
          :src="img.url"
          fit="cover"></el-image>
        <el-tooltip class="item" effect="dark" :content="$t('pages.main.form.image-remove')" placement="top-start">
          <el-icon @click="removeImage(img.id)"><CircleClose /></el-icon>
        </el-tooltip>
      </span>
      <!-- Edit image block-->

      <el-upload
          action="#"
          list-type="picture-card"
          :http-request="submitFile"
          :on-preview="handlePictureCardPreview"
          :before-upload="beforeAvatarUpload"
          :on-remove="handleRemove">
        <el-icon><CirclePlusFilled /></el-icon>
      </el-upload>
      <el-dialog v-model="dialogVisible">
        <img :src="dialogImageUrl" alt="">
      </el-dialog>
      <p style="margin: -10px 0 10px; font-size: 11px;">{{ $t('UI.photo-MB')}}</p>

      <el-form-item>
        <el-button :disabled="!cars.length" size="small" @click="resetForm('car')">{{ $t('UI.buttons.cancel') }}</el-button>
        <el-button :disabled="disabledSubmit" size="small" type="danger" @click="submitForm('car')">{{ $t('UI.buttons.save') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import axios from "axios";
import Gallery from "@/components/app/UI/Gallery";

export default {
  name: "AddCar",
  components: {
    Gallery,
    QuillEditor
  },
  props: {
    title:{
      type: String,
      required: true
    },
    carEdit:{
      type: Object,
      required: false
    }
  },
  data() {
    return{
      car: {
        brand: '',
        model: '',
        yearOfIssue: '',
        vin: '',
        mileage: '',
        mileageBuy: '',
        fuel: '',
        aboutCar: '',
        photos: []
      },
      disabledSubmit: false,
      dialogImageUrl: '',
      dialogVisible: false,
      editPhoto: [],
      rules: {
        brand: [
          { required: true, message: this.$t('pages.main.form.rules.brand'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.main.form.rules.brand-m'), trigger: 'blur' }
        ],
        model: [
          { required: true, message: this.$t('pages.main.form.rules.model'), trigger: 'blur' },
          { min: 3, max: 16, message: this.$t('pages.main.form.rules.model-m'), trigger: 'blur' }
        ],
        mileage: [
          { required: true, message: this.$t('pages.main.form.rules.mileage'), trigger: 'blur' },
        ],
        mileageBuy: [
          { required: true, message: this.$t('pages.main.form.rules.mileage-buy'), trigger: 'blur' },
        ],
        vin: [
          { required: false, message: this.$t('pages.main.form.rules.vin'), trigger: 'blur' },
          { min: 3, max: 20, message: this.$t('pages.main.form.rules.vin-m'), trigger: 'blur' }
        ],
        fuel: [
          { required: true, message: this.$t('pages.main.form.rules.fuel'), trigger: 'change' }
        ],
        aboutCar: [
          { required: true, message: this.$t('pages.main.form.rules.desc'), trigger: 'blur' }
        ],
        yearOfIssue: [
          { type: 'date', required: true, message: this.$t('pages.main.form.rules.date'), trigger: 'change' }
        ]
      }
    }
  },
  computed:{
    ...mapState({
      cars: state => state.car.cars,
      srcImages: state => state.car.carPhoto.imageData
    }),
  },
  methods: {
    ...mapActions({
      fetchCars: 'car/fetchCars',
      addCar: 'car/addCar',
      editCar: 'car/editCar',
    }),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.car.yearOfIssue = String( this.car.yearOfIssue.getFullYear() )

          if(this.carEdit){
            //Update car
            this.editCar({car: this.car, mess: this.$t('pages.main.notification.car-data-save')})
          }else{
            //Add car
            this.addCar({car: this.car, mess: this.$t('pages.main.notification.car-save')})
            this.$router.push('/')
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async submitFile(req) {
      const formData = new FormData();
      formData.append('file', req.file);
      this.disabledSubmit = true
      this.$store.commit('setError', 'imageDownload')

      try {
        const image = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/media',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: formData
        });

        if(this.car.photos === null) this.car.photos = []
        this.car.photos.push({photo: image.data.id})
        this.disabledSubmit = false
      } catch (error) {
        console.error(error);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      console.log(file, 'file');
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' ;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('Picture must be JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Picture size can not exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
    resetForm(formName) {
      if(this.$router.currentRoute.value.hash == '#addCar'){
        this.$router.push('/')
        return true
      }

      this.$refs[formName].resetFields();
      this.$emit('cancel')
    },
    removeImage(id){
      this.car.photos = this.car.photos.filter(i => i.photo !== id)
      this.editPhoto = this.editPhoto.filter(i => i.id !== id)
    }
  },
  mounted() {
    if(this.carEdit){
      this.car.brand = this.carEdit.brand
      this.car.model = this.carEdit.model
      this.car.vin = this.carEdit.vin
      this.car.yearOfIssue = new Date('01.01.' + this.carEdit.yearOfIssue);
      this.car.fuel = this.carEdit.fuel
      this.car.mileage = this.carEdit.mileage
      this.car.mileageBuy = this.carEdit.mileageBuy
      this.car.aboutCar = this.carEdit.aboutCar
      this.car.photos = this.carEdit.photos
      this.editPhoto = this.srcImages

      this.$refs.aboutCarEditor.pasteHTML(this.car.aboutCar)
    }
  },
}
</script>

<style lang="scss">
.wrap_image{
  position: relative;
  padding: 0 10px 0 0;
  display: inline-block;
  .el-icon{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
  .el-form-item__label{
    margin-bottom: 0 !important;
    line-height: 25px !important;
    display: flex !important;
    flex-direction: row-reverse;
    &:before{
      margin-left: 5px;
    }
  }
  .el-form-item{
    margin-bottom: 12px;
  }
  .el-form-item__error{
    font-size: 11px;
  }
  .el-radio{
    margin-right: 20px !important;
  }
  .wysiwyg-quill{
    margin-bottom: 20px;
    .el-form-item__content{
      display: block;
    }
  }
  .el-picker-panel__body-wrapper{
    font-family: 'Exo 2', sans-serif;
  }
  ul.el-upload-list{
    margin-bottom: 20px;
  }
  .ql-editor{
    ul li{
      font-size: 12px !important;
      padding-left: 5px !important;
      &:before{
        left: 0 !important;
      }
    }
  }
</style>