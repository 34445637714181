<template>
  <div v-if="openFlag" style="margin-top: 4px;">
    <el-input-number
        v-model="editMillage"
        :step="100"
        size="small" />
    <el-button
        @click="sendMillage"
        type="primary"
        :disabled="!buttonStatus">
      <el-icon><Check /></el-icon></el-button>
  </div>
</template>

<script>
import { ElNotification } from 'element-plus'

export default {
  name: "editMillage",
  props: {
    millage: {
      type: Number,
      required: true
    },
    flagPopupEditMillage:{
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    editMillage: null,
    buttonStatus: false,
    openFlag: false
  }),
  mounted() {
    this.editMillage = this.millage
  },
  watch:{
    editMillage(){
      if(this.editMillage == this.millage){
        this.buttonStatus = false
      }else{
        this.buttonStatus = true
      }

      // if(this.editMillage < this.millage){
      //   this.buttonStatus = true
      // }else if(this.editMillage == this.millage){
      //   this.buttonStatus = false
      // }else{
      //   this.buttonStatus = true
      // }
    },
    flagPopupEditMillage(){
      this.openFlag = this.flagPopupEditMillage
    }
  },
  methods:{
    async sendMillage(){
      if(this.editMillage == this.millage){
        this.warn(this.$t('pages.main.mileage-equals'))
      }else{
        this.openFlag = false
        await this.$store.dispatch('car/editMillage', {millage: this.editMillage, mess: this.$t('pages.main.mileage-save')})
      }

      // if(this.editMillage < this.millage){
      //   this.warn(this.$t('pages.main.mileage-low'))
      // }else if(this.editMillage == this.millage){
      //   this.warn(this.$t('pages.main.mileage-equals'))
      // }else{
      //   await this.$store.dispatch('car/editMillage', {millage: this.editMillage, mess: this.$t('pages.main.mileage-save')})
      //   this.openFlag = false
      // }
    },
    warn(text){
      ElNotification({
        title: 'Warning',
        message: text,
        type: 'warning',
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button{
    padding: 2px 5px;
    height: 24px;
    margin-left: 3px;
  }
</style>

