<template>
  <div class="header_cont_div" v-if="allPriceSev !== null && countCarMillage !==null">
    <el-tooltip class="item" effect="dark" :content="titleTolltip" placement="top-start">
      <img src="../../../assets/image/vehicle.png" alt="" class="car">
    </el-tooltip>
    <div class="text">
      <span>TOTAL: <b>{{ allPriceSev }}</b>{{ $t('UI.statusContent.UAH') }}</span>
      <div>
        <span>{{countCarMillage}} {{ $t('UI.statusContent.km') }}</span>
        <span>{{ priceTomillage }} {{ $t('UI.statusContent.UAH/km') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "StatusLog",
  computed:{
    ...mapState({
      allPriceSev: state => state.services.allPriceSev,
      countCarMillage: state => state.car.countCarMillage,
    }),
    priceTomillage(){
      const a = this.allPriceSev / this.countCarMillage
      return a.toFixed(2)
    },
    titleTolltip(){
      switch(this.$router.currentRoute.value.name)  {
        case 'logbook':
          return this.$t('UI.statusContent.tooltip-logbook')
          break
        case 'maintenance':
          return this.$t('UI.statusContent.tooltip-maintenance')
          break
        default:
          return this.$t('UI.statusContent.tooltip-maintenance-cost')
      }
    }
  },
}
</script>

<style scoped>

</style>