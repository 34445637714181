<template>
  <el-drawer
      v-model="menuFlag"
      size="300px"
      :show-close="true"
      modal-class="main_drawer"
      :direction="direction"
      :before-close="handleClose"
  >
    <span slot="header">
      <Logo :pData="{type: 'static', url: ''}" />
    </span>
    <slot></slot>
  </el-drawer>
</template>

<script>
import {mapState} from "vuex";
import Logo from "@/components/app/molecules/Logo";

export default {
  name: "Drawer",
  components: {Logo},
  data: () => ({
    direction: 'ltr',
  }),
  computed:{
    ...mapState({
      menuFlag: state => state.menuFlag,
    }),
  },
  methods:{
    handleClose(done) {
      console.log('Close')
      this.$store.commit('setMenuFlag', false)
      done();

      // this.$confirm('Are you sure you want to close this?')
      //     .then(_ => {
      //       done();
      //     })
      //     .catch(_ => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.el-drawer .el-drawer__body > span{
  padding: 20px 0 30px;
  display: block;
}
</style>

<style lang="scss">
.main_drawer > div{
  position: relative;
}
.el-drawer__header{
  position: absolute;
  right: 0;
}
</style>