import { createRouter, createWebHistory } from 'vue-router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import i18n from '@/i18n/i18n'

import Home from '../views/Home.vue'
import Logbook from '../views/Logbook.vue'
import Maintenance from '../views/Maintenance.vue'
import Planing from '../views/Planing.vue'
import Components from '../views/Components.vue'
import Settings from '../views/Settings.vue'
import Fuel from '../views/Fuel.vue'
import Report from '../views/Report.vue'

const routes = [
  {
    path: '/',
    name: 'home',
		meta:{
      layout: 'Main',
      title: i18n.global.t('routers.home'),
      requiresAuth:true
    },
    component: Home
  },
  {
    path: '/logbook',
    name: 'logbook',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.logbook'),
      requiresAuth:true
    },
    component: Logbook
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.history'),
      requiresAuth:true
    },
    component: Maintenance
  },
  {
    path: '/fuel',
    name: 'fuel',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.fuel'),
      requiresAuth:true
    },
    component: Fuel
  },
  {
    path: '/planing',
    name: 'planing',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.planing'),
      requiresAuth:true
    },
    component: Planing
  },
  {
    path: '/components',
    name: 'components',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.components'),
      requiresAuth:true
    },
    component: Components
  },
  {
    path: '/settings',
    name: 'settings',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.settings'),
      requiresAuth:true
    },
    component: Settings
  },
	{
		path: '/login',
		name: 'Login',
		meta:{
      layout: 'Empty',
      title: i18n.global.t('routers.login'),
    },
		component: () => import('../views/login/Login.vue')
	},
  {
    path: '/registration',
    name: 'Registration',
    meta:{
      layout: 'Empty',
      title: i18n.global.t('routers.registration'),
      requiresAuth:true
    },
    component: () => import('../views/login/Register.vue')
  },
  {
    path: '/test',
    name: 'Test',
    meta:{
      layout: 'Main',
      title: 'Test',
    },
    component: () => import('../views/Test.vue')
  },
  {
    path: '/report',
    name: 'report',
    meta:{
      layout: 'Main',
      title: i18n.global.t('routers.report'),
    },
    component: () => import('../views/Report.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

//защита то неавторизированых пользователей
router.beforeEach((to,from,next)=>{
	const isAuthenticated = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record=>record.meta.requiresAuth)

  //add meta title
  document.title = to.meta.title

  //отключена защита роутера
  //next();

  if(requiresAuth && !isAuthenticated){
		next('/login?message=login')
  }else{
    next()
  }
})
export default router
