<template>
  <div>
    <span :class="{ active: language == 'ua' }" @click="setLang('ua')">UA</span> /
    <span :class="{ active: language == 'en' }" @click="setLang('en')">EN</span>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";

export default {
  name: "LanguageSwitch",
  data: () => ({
    language: localStorage.getItem('i18nLanguage') ? localStorage.getItem('i18nLanguage') : 'ua',
  }),
  watch:{
    language(){
      //TODO: reload the page durning switch locale
      window.location.replace(window.location.href)
      if(localStorage.getItem('i18nLanguage') != this.language){
        i18n.global.locale = this.language
        localStorage.setItem('i18nLanguage', this.language);
      }
    }
  },
  methods:{
    setLang(l){
      this.language = l
    }
  }
}
</script>

<style scoped>
span{
  font-size: 12px;
  color: #2e4453;
  cursor: pointer;
}
.active{
  font-weight: bold;
}
div{
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 20px 0 !important;
}
</style>