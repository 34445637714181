import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueMobileDetection from 'vue-mobile-detection'

//locale EN/UA
import {useI18n} from 'vue-i18n'
import i18n from '@/i18n/i18n'


import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/database';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4gcuzbszPh-x_gHKlOzz96rrdjLh_7Y4",
  authDomain: "carlog-a999f.firebaseapp.com",
  databaseURL: "https://carlog-a999f-default-rtdb.firebaseio.com",
  projectId: "carlog-a999f",
  storageBucket: "carlog-a999f.appspot.com",
  messagingSenderId: "469781081703",
  appId: "1:469781081703:web:2442986a0af21d114e2ec2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//нужно для работы firebase auth
//чтоб при перезагрузке не вылетала авторизация
let app
firebase.auth().onAuthStateChanged(() => {
	if(!app){
		app = createApp(App, {
      setup(){
        const {t} = useI18n()
      }
    })
		.use(store)
		.use(router)
		.use(ElementPlus)
		.use(VueMobileDetection)
		.use(i18n)

    //регистрация иконок
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
    app.mount('#app')
  }
})

export { app }