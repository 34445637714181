<template>
  <p class="footer">©{{ copyText }} All rights reserved.</p>
</template>

<script>
export default {
  name: "Footer",
  computed:{
    copyText(){
      const year = new Date().getFullYear()
      return `${year} - ${process.env.VUE_APP_DOMAIN.toUpperCase()}.`
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer{
    font-size: 11px;
    text-align: center;
  }
  .mobile-app{
    .footer{
      width: 100%;
      margin: 0;
      padding: 12px 0;
      background: #fff;
    }
  }
</style>