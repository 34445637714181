<template>
  <div class="logbook" v-loading="loading">
    <div v-if="editServiceStatus === false">
      <AddService
          :title="$t('pages.logbook.title-maintenance')"
          :description="$t('pages.logbook.desc-maintenance')"
          :postIsEmpty="true"
          v-if="openAddForm == true"/>
          <!--v-if="Object.keys(services).length <= 0 || openAddForm == true"-->

      <ListLogbook
          v-else
          :title="$t('pages.logbook.title-maintenance')"
          :description="$t('pages.logbook.desc-maintenance')"
          :services = services
          @edit="editService"
      />
    </div>

    <AddService
        v-else
        :title="$t('pages.logbook.edit-note')"
        @cancel="setEditCarStatus(false)"
        :postEdit="editPost"/>
  </div>
</template>

<script>
import ListLogbook from "@/components/logBook/ListLogbook";
import AddService from "@/components/logBook/AddService";
import {mapState,mapMutations} from "vuex";

export default {
  name: 'Maintenance',
  data: () => ({
    editPost: {},
    // 2 - id category Car Maintenance
    catID: 2
  }),
  components: {
    AddService,
    ListLogbook
  },
  computed:{
    ...mapState({
      services: state => state.services.services,
      loading: state => state.services.loading,
      editServiceStatus: state => state.services.editServiceStatus,
      openAddForm: state => state.services.openAddForm,
    }),
  },
  async mounted() {
    //this.$store.commit('services/setLoading', true)
    this.$store.commit('services/setCategoryPost', this.catID)

    await this.$store.dispatch('services/fetchService')
    //this.$store.dispatch('car/fetchCars')

    //this.services = this.$store.state.services.services
    this.$store.commit('services/changeOpenAddForm', false)
  },
  created() {
    this.$store.commit('services/setEditServiceStatus', false)
  },
  methods:{
    editService(post){
      this.editPost = post
      this.$store.commit('services/setEditServiceStatus', true)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>