<template>
  <div class="addFuel">
    <el-form
        :model="fuel"
        :rules="rules"
        ref="fuel"
    >
      <el-form-item :label="$t('pages.fuel.form.mileage')" prop="mileage_f">
        <el-input-number
            v-model="fuel.mileage_f"
            :step="100"
            size="small"
            :min="1"/>
      </el-form-item>

      <el-form-item :label="$t('pages.fuel.form.date')" prop="date">
        <el-date-picker
            v-model="fuel.date"
            type="date"
            size="small"
            style="display: block; margin-top: 3px; max-width: 100px"
            :placeholder="$t('pages.fuel.form.date')">
        </el-date-picker>
      </el-form-item>

      <el-form-item :label="$t('pages.fuel.form.count-liters')" prop="liters">
        <el-input-number
            v-model="fuel.liters"
            :step="1"
            :min="1"
            size="small" />
      </el-form-item>

      <el-form-item :label="$t('pages.fuel.form.cost')" prop="price">
        <el-input
            v-model="fuel.price"
            type="tel"
            :step="10"
            :min="0"
            class="price-c"
            v-maska
            data-maska="0.99"
            data-maska-tokens="0:\d:multiple|9:\d:optional"
            placeholder="0.00"
            size="small" />
      </el-form-item>

      <el-form-item :label="$t('pages.fuel.form.comment')">
        <el-input
            placeholder="WOG, OKKO"
            size="small"
            style="margin-top: 3px;"
            v-model="fuel.description">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('pages.fuel.form.full-tank')" class="fullTank">
        <el-switch size="small" v-model="fuel.fullTank">
        </el-switch>
      </el-form-item>

      <el-button
          @click="submitForm('fuel')"
          type="primary"
          class="btn-cust"
          style="background: #D80128; border: 1px solid #000; opacity: 0.8;">
        <el-icon><Check /></el-icon></el-button>
    </el-form>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { v4 as uuidv4 } from 'uuid';
import { vMaska } from "maska"

export default {
  name: "addFuel",
  directives: { maska: vMaska },
  data(){
    return{
      fuel:{
        id: '',
        mileage_f: 1,
        date: new Date(),
        liters: 20,
        price: '',
        description: '',
        fullTank: false,
      },
      rules: {
        mileage_f: [
          {required: true, message: this.$t('pages.fuel.form.rules.mileage'), trigger: 'blur'},
        ],
        date: [
          {required: true, message: this.$t('pages.fuel.form.rules.date'), trigger: 'blur'},
        ],
        liters: [
          {required: true, message: this.$t('pages.fuel.form.rules.liters'), trigger: 'blur'},
        ],
        price: [
          {required: true, message: this.$t('pages.fuel.form.rules.cost'), trigger: 'blur'},
        ],
      },
    }
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fuel.id = uuidv4()
          this.$emit('add', this.fuel)

          if(this.car.acf.mileage< this.fuel.mileage_f){
            this.$store.dispatch('car/editMillage', {millage: this.fuel.mileage_f, mess: this.$t('pages.fuel.notification.fuel-save')})
          }
          // this.fuel.liters = 20
          // this.fuel.price = 0
          //this.resetForm('fuel')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed:{
    ...mapState({
      car: state => state.car.carObject,
    }),
  },
  async beforeCreate(){

  },
  async mounted() {
    this.fuel.mileage_f = this.car.acf.mileage
  }
}
</script>

<style lang="scss">
.mobile-app{
  @media screen and (max-width: 767px) {
    .addFuel {
      form {
        flex-wrap: wrap;
        justify-content: flex-start;
        & > div {
          margin: 0 5px;
          flex: 0 1 20%;
          max-width: 95px;
        }
      }
    }
    .addFuel + div{
      overflow-x: scroll;
      max-width: calc(100vw - 30px);
    }
  }
}
</style>

<style lang="scss" scoped>
.addFuel{
  form{
    display: flex;
    align-items: flex-end;
    margin: 0 -5px;
    &>div{
      margin: 0 5px;
    }
  }
  .el-input-number{
    display: block;
    margin-top: 3px;
  }
}
.el-date-editor.el-input{
  display: block;
  margin-top: 3px;
}
.btn-cust.is-disabled{
  opacity: 0.6;
}
.el-form-item{
  flex-direction: column;
}
.el-switch--small{
  height: 27px;
  padding-top: 3px;
}
</style>

<style lang="scss">
.addFuel {
  .el-form-item__label{
    height: 22px;
    margin-bottom: 3px;
    font-size: 12px;
  }
  .el-form-item__error{
    font-size: 10px;
  }
  .el-form-item.is-error .el-input__wrapper{
    box-shadow: none;
    border: 1px solid #E24A56;
  }
}
</style>