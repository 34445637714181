<template>
  <header>
    <Logo :pData="{type: 'router', url: '/'}" />

    <StatusContant/>

<!--    <div class="profile_wrap">-->
<!--      <div class="profile">-->
<!--        <img src="../../assets/image/profile.png" alt="" class="logo">-->
<!--      </div>-->
<!--    </div>-->

    <div class="garage">
      <el-dropdown>
        <img src="../../assets/image/garage-1.png" alt="">

        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
                v-for="car in cars"
                @click="$store.dispatch('car/setCurrentCar', car.id)"
                :class="{ current: car.id == currentCarID }">{{ car.acf.brand }} {{car.acf.model}}, {{ car.acf.yearOfIssue }}</el-dropdown-item>

            <el-dropdown-item class="add_car" @click="$router.push('/#addCar')" :divided="cars.length">{{ $t('UI.add-car') }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import StatusContant from "@/components/app/organisms/StatusContant";
import {mapActions, mapState} from "vuex";
import Logo from "@/components/app/molecules/Logo";
export default {
  name: "Header.vue",
  components: {Logo, StatusContant},
  methods: {
    ...mapActions({
      fetchCars: 'car/fetchCars'
    }),
  },
  computed:{
    ...mapState({
      cars: state => state.car.cars,
      currentCarID: state => state.car.currentCar
    }),
  },
  mounted() {
    if(!this.cars.length > 0) {
      this.fetchCars()
    }
  }
}
</script>

<style lang="scss" scoped>
  header{
    display: flex;
    .profile_wrap{
      display: flex;
      justify-content: flex-end;
      .profile{
        background: #97b5c2;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        width: 50px;
        height: 50px;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 36px;
        }
      }
    }
    .garage{
      img{
        width: 50px;
        cursor: pointer;
      }
    }
  }
</style>

<style lang="scss">
.el-dropdown-menu__item{
  font-size: 12px;
  padding: 4px 14px;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover{
    opacity: 0.9;
  }
  &.current{
    font-weight: bold;
  }
  &.add_car{
    padding: 0 14px;
    font-size: 11px;
    display: flex;
    justify-content: center;
  }
}
</style>