<template>
  <div class="header_cont">
    <div v-if="$router.currentRoute.value.name == 'home' || $router.currentRoute.value.name == 'logbook' || $router.currentRoute.value.name == 'maintenance' ">
      <StatusLog />

      <StatusFuel />
    </div>

    <div v-if="$router.currentRoute.value.name == 'fuel' || $router.currentRoute.value.name == 'planing'">
      <StatusFuel class="hide-line" />
    </div>

    <div class="header_cont__components" v-if="$router.currentRoute.value.name == 'components'">
      <h1>{{ $t('pages.components.title') }}</h1>
      <p>{{ $t('pages.components.desc') }}</p>
    </div>

    <div class="header_cont__components" v-if="$router.currentRoute.value.name == 'report'">
      <h1>{{ $t('pages.report.title') }}</h1>
      <p>{{ $t('pages.report.desc') }}</p>
    </div>
  </div>
</template>

<script>
import StatusFuel from "@/components/app/organisms/StatusFuel";
import StatusLog from "@/components/app/organisms/StatusLog";
import {mapState} from "vuex";

export default {
  name: "StatusContant",
  components: {StatusLog, StatusFuel},
  computed:{
    ...mapState({
      allPriceSev: state => state.services.allPriceSev,
      countCarMillage: state => state.car.countCarMillage,
    }),
    priceTomillage(){
      const a = this.allPriceSev / this.countCarMillage
      return a.toFixed(2)
    }
  },
  methods:{

  },
}
</script>

<style lang="scss">
.header_cont{
  width: 100%;
  display: flex;
  align-items: center;
  > div, .header_cont_div{
    display: flex;
    align-items: center;
    img{
      width: 32px;
      margin-right: 10px;
    }
    span{
      padding-top: 8px;
    }
  }
  .text{
    display: flex;
    align-items: center;
    &>div{
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      padding-top: 8px;

      span{
        font-size: 12px;
        line-height: 12px;
        padding: 0;
        font-weight: bold;
      }
    }
  }
  .header_cont__components{
    display: block;
    max-width: 680px;
    padding-top: 20px;
    h1{
      margin-bottom: 5px;
      line-height: 100%;
    }
    p{
      margin: 0;
      padding-bottom: 10px;
    }
  }
  .fuel_wrap{
    margin-left: 20px;
    display: flex;
    align-items: center;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      left: -10px;
      top: 2px;
      width: 1px;
      height: 100%;
      background: #494a4d;

    }
    img{
      width: 25px;
      margin-right: 5px;
      margin-top: 3px;
    }
  }
  .hide-line{
    margin-left: 0;
    &:before{
      display: none;
    }
  }
}

/*#58B7E4*/
</style>