<template>
  <div class="components" v-loading="loading">
    <div class="todo-comp">
      <add-components @add="addComp"/>

      <list-components @remove="removeComp" @open="openEditComp" :components="components"/>

      <edit-components @change="changeComp" :editComp="editComponent"/>
    </div>
  </div>
</template>

<script>
import ListComponents from "@/components/carComponents/ListComponents";
import AddComponents from "@/components/carComponents/AddComponents";
import EditComponents from "@/components/carComponents/EditComponents";
import {mapActions, mapState, mapMutations} from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'components',
  components: {
    ListComponents, AddComponents, EditComponents
  },
  data: () => ({
    editComponent:{
      dialogVisible: false,
      component: ''
    }
  }),
  computed:{
    ...mapState({
      components: state => state.comp.components,
      loading: state => state.comp.loading,
    }),
  },
  watch:{},
  methods:{
    ...mapActions({
      fetchComponents: 'comp/fetchComponents',
    }),
    openEditComp(component){
      this.editComponent = {
        dialogVisible: true,
        component: {...component}
      }
    },
    addComp(component){
      this.$store.dispatch('comp/addComponent', component)
    },
    changeComp(component){
      this.$store.dispatch('comp/editComponent', component)
    },
    removeComp(component){
      this.$store.dispatch('comp/removeComponent', component)
    }
  },
  async mounted(){
    if(!this.components.length > 0) {
      this.$store.commit('comp/setLoading', true)

      await this.fetchComponents()
    }
  }
}
</script>

<style lang="scss">
.components.el-loading-parent--relative{
  height: 100%;
  .el-loading-mask{
    background: #ffffff;
  }
}
</style>

<style lang="scss" scoped>
.components{
  padding-right: 25px
}
.todo-comp{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
</style>