<template>
  <el-dialog :title="$t('pages.fuel.edit-title')" v-model="dialogFormVisible" class="addFuel" style="max-width: 380px; width: 95%;">
    <el-form
        :model="fuel"
        :rules="rules"
        ref="fuel"
    >
      <div class="col-2">
        <el-form-item :label="$t('pages.fuel.form.mileage')" prop="mileage_f">
          <el-input-number
              v-model="fuel.mileage_f"
              :step="100"
              size="small"
              :min="1"/>
        </el-form-item>

        <el-form-item :label="$t('pages.fuel.form.date')" prop="date">
          <el-date-picker
              v-model="fuel.date"
              type="date"
              size="small"
              style="display: block; margin-top: 3px; max-width: 100px"
              :placeholder="$t('pages.fuel.form.date')">
          </el-date-picker>
        </el-form-item>
      </div>

      <div class="col-2">
        <el-form-item :label="$t('pages.fuel.form.liters')" prop="liters">
          <el-input-number
              v-model="fuel.liters"
              :step="1"
              :min="1"
              size="small"/>
        </el-form-item>

        <el-form-item :label="$t('pages.fuel.form.cost')" prop="price">
          <el-input-number
              v-model="fuel.price"
              type="tel"
              :step="10"
              :min="0"
              size="small"/>
        </el-form-item>
      </div>

      <div class="col-2">
        <el-form-item :label="$t('pages.fuel.form.comment')">
          <el-input
              placeholder="WOG, OKKO"
              size="small"
              style="margin-top: 3px;"
              v-model="fuel.description">
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('pages.fuel.form.full-tank')" class="fullTank">
          <el-switch size="small" v-model="fuel.fullTank">
          </el-switch>
        </el-form-item>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('fuel')">{{ $t('UI.buttons.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="submitForm('fuel')">{{ $t('UI.buttons.edit') }}</el-button>
      </span>
    </el-form>
  </el-dialog>
</template>

<script>
import {v4 as uuidv4} from "uuid";

export default {
  name: "EditFuel",
  props: {
    editFuel:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
      dialogFormVisible: false,
      fuel: '',
      rules: {
        mileage_f: [
          {required: true, message: this.$t('pages.fuel.form.rules.mileage'), trigger: 'blur'},
        ],
        date: [
          {required: true, message: this.$t('pages.fuel.form.rules.date'), trigger: 'blur'},
        ],
        liters: [
          {required: true, message: this.$t('pages.fuel.form.rules.liters'), trigger: 'blur'},
        ],
        price: [
          {required: true, message: this.$t('pages.fuel.form.rules.cost'), trigger: 'blur'},
        ],
      },
    }
  },
  watch:{
    editFuel: function (){
      this.dialogFormVisible = this.editFuel.dialogVisible
      this.fuel = this.editFuel.fuel
    },
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //this.$emit('change', this.fuel)
          this.$store.dispatch('fuel/editFuels', this.fuel)

          this.dialogFormVisible = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.dialogFormVisible = false
    }
  },
}
</script>



<style lang="scss" scoped>
  .el-form-item{
    flex-direction: column;
  }
</style>

<style lang="scss">
.mobile-app{
  @media screen and (max-width: 767px) {
    .el-dialog.addFuel {
      form {
        & > div {
          margin: 0 5px;
          max-width: 100%;
          flex-direction: row;
          .fullTank{
            padding-left: 5px;
          }
        }
      }
    }

  }
}
</style>