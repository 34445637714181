<template></template>

<script>
export default {
  name: "Notifications",
  data(){
    return{
      messages: {
        //?=message=login
        //tupe : 'warning' || 'info' || 'error' || 'success' || ''
        //this.$store.commit('setError', 'imageDownload')
        'logout': {type: '', title: 'Logout', text: this.$t('UI.notification.logout')},
        'login': {type: '', title: 'Info', text: this.$t('UI.notification.login')},
        'imageDownload': {type: 'info', title: this.$t('UI.notification.imageDownload'), text: this.$t('UI.notification.imageDownload-t')},
        'auth/user-not-found': this.$t('UI.notification.auth-user-not-found'),
        'auth/wrong-password': this.$t('UI.notification.auth-wrong-password'),
        'auth/email-already-in-use': this.$t('UI.notification.auth=email-already-in-use')
      }
    }
  },
  computed: {
    error(){
      return this.$store.getters.error
    },
    message(){
      return this.$store.getters.message
      // commit('setMessage', {type: '', text: 'mess'}, {root: true}) - message
      // commit('setMessage', {type: 'warning', text: 'mess'}, {root: true}) - warning
      // commit('setMessage', {type: 'success', text: 'mess'}, {root: true}) - success
      // commit('setMessage', {type: 'error', text: 'mess'}, {root: true}) - error
    }
  },
  watch: {
    async error(fbError) {
      if(fbError === null){
        return false
      }

      console.log(fbError)
      const message = this.messages[fbError]
      console.log(message)

      await this.$notify({
        title: message.title,
        message: message.text,
        type: message.type
      });

      this.$store.commit('clearError')
    },

    message(){
      if(this.message.text.length > 0 && this.message.type == 'error'){
        this.$message.error(this.message.text);
      }else if(this.message.text.length > 0){
        this.$message({
          message: this.message.text,
          type: this.message.type ? this.message.type : ''
        });
      }
    },
  }
}
</script>

<style scoped>

</style>