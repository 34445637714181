<template>
  <div
      v-if="this.$store.state.services.allServices.length > 0 || this.$store.state.services.services.length > 0 || this.$store.state.fuel.fuels.length > 0"
      class="drawerBottom_button"
      @click="drawer = !drawer">
    <img src="../../../assets/image/car.png" alt="">
  </div>

  <el-drawer
      v-model="drawer"
      direction="btt"
      size="130px"
      modal-class="drawerBottom"
      :before-close="handleClose">
    <div class="bottom_cont">
      <StatusLog />

      <StatusFuel />
    </div>
  </el-drawer>
</template>

<script>
import StatusLog from "@/components/app/organisms/StatusLog";
import StatusFuel from "@/components/app/organisms/StatusFuel";

export default {
  name: "DrawerBottom",
  components: {StatusLog, StatusFuel},
  data: () => ({
    drawer: false,
    direction: 'rtl',
  }),
  methods: {
    handleClose(done) {
      done();
    }
  }
}
</script>

<style lang="scss" scoped>
.drawerBottom_button{
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #EBF0F6;
  padding: 10px;
  border-radius: 3px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  img{
    width: 35px;
  }
}
</style>

<style lang="scss">
.drawerBottom{
  .el-drawer__header{
    padding: 8px;
    margin-bottom: 10px;
  }
  .el-drawer__body{
    padding: 10px 20px;
  }
}
.bottom_cont{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div, .header_cont_div{
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    img{
      width: 32px;
      margin-right: 10px;
    }
    span{
      padding-top: 8px;
    }
  }
  .text{
    display: flex;
    align-items: center;
    &>div{
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      padding-top: 8px;

      span{
        font-size: 12px;
        line-height: 12px;
        padding: 0;
        font-weight: bold;
      }
    }
  }
  .header_cont_div{
    border-bottom: 1px solid;
    padding-bottom: 12px;
  }
  .header_cont__components{
    display: block;
    max-width: 680px;
    padding-top: 20px;
    h1{
      margin-bottom: 5px;
      line-height: 100%;
    }
    p{
      margin: 0;
      padding-bottom: 10px;
    }
  }
  .fuel_wrap{
    display: flex;
    align-items: center;
    position: relative;
    img{
      width: 25px;
      margin-right: 5px;
      margin-top: 3px;
    }
  }
  .hide-line{
    margin-left: 0;
    &:before{
      display: none;
    }
  }
}
</style>