<template>
  <div class="sidebar_wrap" v-if="!$isMobile()">
    <ul class="sidebar__nav">
      <router-link
          tag="li"
          v-for="link in links"
          :key="link.url"
          :to="link.url"
          :exact="link.exact"
          :class="{ disabled: link.disabledCarEmpty, on: !cars.length}"
          class="link">
        <component :is="link.icon"></component>
        {{ link.title }}
      </router-link>
    </ul>

    <div class="sidebar__nav_bottom">
      <router-link
          tag="li"
          v-for="link in bottomLinks"
          :key="link.url"
          :to="link.url"
          :exact="link.exact"
          :class="{ disabled: link.disabledCarEmpty, on: !cars.length}"
          class="link">
        <component :is="link.icon"></component>
        {{ link.title }}
      </router-link>
      <a href="#" class="link logout" @click.prevent="logout">
        <el-icon><DArrowLeft /></el-icon>
        {{ $t('UI.sidebar.logout') }}</a>
    </div>
  </div>

  <Drawer v-else>
    <ul class="sidebar__nav">
      <router-link
          tag="li"
          v-for="link in links"
          :key="link.url"
          :to="link.url"
          :exact="link.exact"
          :class="{ disabled: link.disabledCarEmpty, on: !cars.length}"
          @click="closeDrawer()"
          class="link">
        <component :is="link.icon"></component>
        {{ link.title }}
      </router-link>
    </ul>

    <div class="sidebar__nav_bottom">
      <router-link
          tag="li"
          v-for="link in bottomLinks"
          :key="link.url"
          :to="link.url"
          :exact="link.exact"
          :class="{ disabled: link.disabledCarEmpty, on: !cars.length}"
          @click="closeDrawer()"
          class="link">
        <component :is="link.icon"></component>
        <span @click="InlineButtonClickHandler"
        >{{ link.title }}</span>
      </router-link>
      <a href="#" class="link logout" @click.prevent="logout">
        <el-icon><DArrowLeft /></el-icon>
        {{ $t('UI.sidebar.logout') }}</a>
    </div>
  </Drawer>
</template>

<script>
import {mapState} from "vuex";
import Drawer from "@/components/app/organisms/Drawer";

export default {
  components: {Drawer},
  data(){
    return{
      links: [
        {title: this.$t('UI.sidebar.car'), icon: 'Compass', url: "/", exact: false},
        {title: this.$t('UI.sidebar.maintenance'), icon: 'CirclePlus', url: "/maintenance", exact: false, disabledCarEmpty: true},
        {title: this.$t('UI.sidebar.logbook'), icon: 'Odometer', url: "/logbook", exact: false, disabledCarEmpty: true},
        {title: this.$t('UI.sidebar.fuel'), icon: 'Place', url: "/fuel", exact: false, disabledCarEmpty: true},
        {title: this.$t('UI.sidebar.planning'), icon: 'CircleCheck', url: "/planing", exact: false, disabledCarEmpty: true},
        {title: this.$t('UI.sidebar.car-components'), icon: 'WalletFilled', url: "/components", exact: false, disabledCarEmpty: true},
        {title: this.$t('UI.sidebar.report'), icon: 'Tickets', url: "/report", exact: false, disabledCarEmpty: true},
      ],
      bottomLinks: [
        {title: this.$t('UI.sidebar.settings'), icon: 'Tools', url: "/settings", exact: false},
        //{title: "Выйти", icon: 'DArrowLeft', url: "/logout", exact: false},
      ]
    }
  },
  // data: () => ({
  //   links: [
  //     {title: "Машина", icon: 'Compass', url: "/", exact: false},
  //     {title: "Бортовик", icon: 'Odometer', url: "/logbook", exact: false, disabledCarEmpty: true},
  //     {title: "ТО автомобиля", icon: 'CirclePlus', url: "/history", exact: false, disabledCarEmpty: true},
  //     {title: "Топливо", icon: 'Place', url: "/fuel", exact: false, disabledCarEmpty: true},
  //     {title: "Планировщик дел", icon: 'CircleCheck', url: "/planing", exact: false, disabledCarEmpty: true},
  //     {title: "Комплектующие", icon: 'WalletFilled', url: "/components", exact: false, disabledCarEmpty: true},
  //   ],
  //   bottomLinks: [
  //     {title: "Настройки", icon: 'Tools', url: "/settings", exact: false},
  //     //{title: "Выйти", icon: 'DArrowLeft', url: "/logout", exact: false},
  //   ]
  // }),
  computed:{
    ...mapState({
      cars: state => state.car.cars,
    }),
  },
  methods:{
    async logout(){
      await this.$store.dispatch('authPhone/logout')
      this.$router.push('/login')
    },
    closeDrawer(){
      this.$store.commit('setMenuFlag', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .sidebar_wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 210px;
  }
  .sidebar__nav{
    padding-left: 0;
    //flex: 0 0 210px;
    margin: 0 25px 0 0;
  }
  .link{
    color: #02b7bb;
    margin-bottom: 10px;
    display: inline-block;
    transition: 0.3s;
    font-family: 'Helvetica Neue';
    font-weight: 600;
    font-size: 12px;
    padding: 9px 10px 9px 10px;
    border-radius: 10px;
    text-align: left;
    display: flex;
    align-items: center;
    &.logout{
      i{
        width: 18px;
        margin-right: 15px;
      }
      svg{
        margin-right: 0;
      }
    }
    svg{
      width: 18px;
      margin-right: 15px;
    }
    &:hover{
      opacity: 0.7;
    }
    &.router-link-active{
      background: #02b7bb;
      color: #ffffff;
      position: relative;
      &:before{
        content: "";
        width: 100%;
        height: 100%;
        left: calc(-100% - 10px);
        top: 0;
        background: #02b7bb;
        border-radius: 10px;
        position: absolute;
      }
    }
  }
  .disabled.on {
    opacity: 0.5;
    pointer-events: none;
  }
</style>

<!--isCollapse: false,-->
<!--handleOpen(key, keyPath) {-->
<!--console.log(key, keyPath);-->
<!--},-->
<!--handleClose(key, keyPath) {-->
<!--console.log(key, keyPath);-->
<!--}-->
<!--<style lang="scss">-->
<!--.el-menu-vertical-demo{-->
<!--  list-style: none;-->
<!--  margin-left: 0;-->
<!--  padding-left: 0 !important;-->
<!--  li:before{-->
<!--    display: none;-->
<!--  }-->
<!--}-->
<!--.el-menu-vertical-demo:not(.el-menu&#45;&#45;collapse) {-->
<!--  width: 200px;-->
<!--  min-height: 400px;-->
<!--}-->
<!--</style>-->

<!--<el-radio-group v-model="isCollapse" style="margin-bottom: 20px">-->
<!--<el-radio-button :label="false">expand</el-radio-button>-->
<!--<el-radio-button :label="true">collapse</el-radio-button>-->
<!--</el-radio-group>-->
<!--<el-menu-->
<!--    default-active="2"-->
<!--    class="el-menu-vertical-demo"-->
<!--    :collapse="isCollapse"-->
<!--    @open="handleOpen"-->
<!--    @close="handleClose"-->
<!--&gt;-->
<!--<el-sub-menu index="1">-->
<!--  <template #title>-->
<!--    <el-icon><location /></el-icon>-->
<!--    <span>Navigator One</span>-->
<!--  </template>-->
<!--  <el-menu-item-group>-->
<!--    <template #title><span>Group One</span></template>-->
<!--    <el-menu-item index="1-1">item one</el-menu-item>-->
<!--    <el-menu-item index="1-2">item two</el-menu-item>-->
<!--  </el-menu-item-group>-->
<!--  <el-menu-item-group title="Group Two">-->
<!--    <el-menu-item index="1-3">item three</el-menu-item>-->
<!--  </el-menu-item-group>-->
<!--  <el-sub-menu index="1-4">-->
<!--    <template #title><span>item four</span></template>-->
<!--    <el-menu-item index="1-4-1">item one</el-menu-item>-->
<!--  </el-sub-menu>-->
<!--</el-sub-menu>-->
<!--<el-menu-item index="2">-->
<!--  <el-icon><ChatRound /></el-icon>-->
<!--  <template #title>Navigator Two</template>-->
<!--</el-menu-item>-->
<!--<el-menu-item index="3" disabled>-->
<!--  <el-icon><document /></el-icon>-->
<!--  <template #title>Navigator Three</template>-->
<!--</el-menu-item>-->
<!--<el-menu-item index="4">-->
<!--  <el-icon><setting /></el-icon>-->
<!--  <template #title>Navigator Four</template>-->
<!--</el-menu-item>-->
<!--</el-menu>-->