<!-- //Todo - do select fuel consumption - month, weak, year. Or add point for reset fuel consumption  -->

<template>
  <div class="fuel">
    <h1>{{ $t('pages.fuel.title') }}</h1>
    <p>{{ $t('pages.fuel.desc') }}</p>

    <add-fuel @add="addFuel" />

    <list-fuels v-loading="loading" @remove="removeFuel" @editFuel="openEditFuel" :fuels="fuels"/>

    <EditFuel @change="changeFuel" :editFuel="editFuel"/>
  </div>
</template>

<script>
import AddFuel from "@/components/fuel/addFuel";
import ListFuels from "@/components/fuel/ListFuels";
import EditFuel from "@/components/fuel/EditFuel";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Fuel',
  data: () => ({
    editFuel:{
      dialogVisible: false,
      Fuel: ''
    }
  }),
  components: {
    EditFuel, ListFuels, AddFuel
  },
  computed:{
    ...mapState({
      fuels: state => state.fuel.fuels,
      loading: state => state.fuel.loading,
    }),
  },
  watch:{},
  methods:{
    ...mapActions({
      fetchFuel: 'fuel/fetchFuels',
    }),
    async addFuel(fuel){
      this.$store.dispatch('fuel/addFuels', fuel)
    },
    // changeFuel(fuel){
    //   this.$store.dispatch('fuel/editFuels', fuel)
    // },
    removeFuel(fuel){
      this.$store.dispatch('fuel/removeFuels', fuel)
    },
    openEditFuel(fuel){
      this.editFuel = {
        dialogVisible: true,
        fuel: {...fuel}
      }
    },
    getDate( d ){
      const date = new Date(d);
      return date.toLocaleString('ru', {day: 'numeric', month: 'numeric', year: 'numeric'})
    },
  },
  async mounted(){
    if(!this.fuels.length > 0) {
      this.$store.commit('fuel/setLoading', true)

      await this.fetchFuel()
    }
  }
}
</script>

<style lang="scss" scoped>
  h1{
    margin-bottom: 0;
    &+p{
      margin-top: 0;
    }
  }
</style>