import axios from "axios";
import {dataLog} from "@/helpers/main";
import i18n from '@/i18n/i18n'

export const components = {
  state: () => ({
    components: [],
    loading: true
  }),
  getters: {
    getCurrentCar(state, _, rootState) {
      return rootState.car.currentCar;
    },
  },
  mutations: {
    setComponents(state, comp) {
      state.components = comp;
    },
    setLoading(state, flag){
      state.loading = flag
    },
    resetComponents(state) {
      state.components = [];
    },
  },
  actions: {
    async fetchComponents({state, commit, getters}){
      if(getters.getCurrentCar === null) return false

      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + getters.getCurrentCar,
        })
        dataLog(response.data.acf.components, 'components')

        if(response.data.acf.components){
          commit('setComponents', response.data.acf.components)
        }
        state.loading = false
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    addComponent({state, dispatch}, comp){
      state.components.unshift(comp)
      dispatch('updateComponent', {comp: state.components, mess: i18n.global.t('store.add-record')})
    },

    editComponent({state, dispatch}, comp){
      const i = state.components.findIndex(c => c.id === comp.id)
      state.components[i] = comp;
      dispatch('updateComponent', {comp: state.components, mess: i18n.global.t('store.edit-record')})
    },

    removeComponent({state, dispatch}, comp){
      state.components = state.components.filter( c => c.id !== comp.id)
      dispatch('updateComponent', {comp: state.components, mess: i18n.global.t('store.delete-record')})
    },

    async updateComponent({state, commit, dispatch, getters}, {comp, mess}){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + getters.getCurrentCar,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            acf: {
              components: comp
            }
          })
        })
        commit('setMessage', {type: 'success', text: mess}, {root: true})
        //commit('setComponents', response.data.acf.components)
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    }
  },
  namespaced: true
}