import firebase from 'firebase/compat/app';
import i18n from '@/i18n/i18n'

export const info = {
  state: () => ({
    info: '',
  }),
  getters: {
    info: state => state.info,
  },
  mutations: {
    setInfo(state, info) {
      state.info = info
    },
    resetInfoData(state){
      state.info = ''
    }
  },
  actions: {
    async fetchInfo( {dispatch, commit} ) {
      try{
        const uid = await dispatch('getUid')
        const info = (await firebase.database().ref(`/users/${uid}/info`).once('value')).val()
        commit('setInfo', info)

        if(info === null) return false
        if(info.currentCar) commit('car/setCurrentCar', info.currentCar, {root: true})
        if(info.userIDWP) commit('setCurrentUserID', info.userIDWP, {root: true})

        //TODO: remain bug when change locale during login - title not translate until the page is reloaded
        //window.location.replace(window.location.href);

        //save locale to DB - если в localStore стоит другой язык чем в БД, данные из localStorege сохранятся в БД
        const langStore = localStorage.getItem('i18nLanguage')
        if(langStore != info.lang){
          dispatch('saveLanguage', langStore)
        }

        return info
      } catch(e){
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        throw e
      }
    },

    async updateInfo({commit, dispatch, state}, update){
      const uid = await dispatch('getUid')
      const profile = await firebase.database().ref(`/users/${uid}/info`).update(update)

      state.info = {...state.info, ...update}

      //change locale
      const lang = state.info.lang || 'en'
      localStorage.setItem('i18nLanguage', lang);
      //window.location.replace(window.location.href);
      //i18n.global.locale = lang

      commit('setMessage', {type: 'success', text: i18n.global.t('store.save-data')}, {root: true})
    },

    async saveLanguage({commit, dispatch, state}, lang){
      const uid = await dispatch('getUid')
      await firebase.database().ref(`/users/${uid}/info/`).update({lang: lang})
    },

    async removeCurrentCarWB({dispatch, state}){
      const uid = await dispatch('getUid')
      await firebase.database().ref(`/users/${uid}/info/currentCar/`).remove()
    },

    getUid(){
      const user = firebase.auth().currentUser
      return user ? user.uid : null
    }
  },
  namespaced: true
}

// async getUidWP({commit}){
//   const user = await axios({
//     method: 'POST',
//     url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/users/me',
//     headers: {
//       'Content-Type': 'application/json',
//       'accept': 'application/json',
//       'Authorization': `Bearer ${localStorage.getItem('jwt')}`
//     },
//   });
//   console.log(user)
//
//   commit('setCurrentUserID',  user.data.id, {root: true})
// },