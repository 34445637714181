<template>
  <h1>{{ title }}</h1>
  <p v-if="postIsEmpty === true ">{{ description }}</p>
  <p v-else-if="futureSevice === 'true'">{{ $t('pages.logbook.add-note') }}</p>
  <el-form
      :model="service"
      :rules="rules"
      ref="service"
      label-width="120px"
      class="demo-ruleForm"
      label-position="top"
      style="max-width: 650px">
    <el-form-item :label="$t('pages.logbook.form.name')" prop="title">
      <el-input v-model="service.title"></el-input>
    </el-form-item>
    <div class="col-2">
      <el-form-item v-if="futureSevice === 'true'" :label="$t('pages.logbook.form.mileage_f')" prop="mileage">
        <el-input-number
            controls-position="right"
            v-model="service.mileage"
            :min="1"
            :max="1000000"
            style="margin-right: 10px">
        </el-input-number>
        {{ $t('UI.statusContent.km') }}
      </el-form-item>

      <el-form-item v-else :label="$t('pages.logbook.form.mileage')" prop="mileage">
        <el-input-number
            controls-position="right"
            v-model="service.mileage"
            :min="1"
            :max="999999"
            style="margin-right: 10px; height: 32px;">
        </el-input-number>
        {{ $t('UI.statusContent.km') }}
      </el-form-item>

      <el-form-item :label="$t('pages.logbook.form.date')" prop="date" required>
        <el-date-picker
            v-model="service.date"
            type="date"
            :placeholder="$t('pages.logbook.form.date-p')">
        </el-date-picker>
      </el-form-item>

      <el-form-item v-if="futureSevice == 'true'" :label="$t('pages.logbook.form.cost_f')" prop="price" required>
        <el-input
            v-model="service.price"
            :max="1000000"
            class="price-c"
            v-maska
            data-maska="0.99"
            data-maska-tokens="0:\d:multiple|9:\d:optional"
            placeholder="0.00"
            type="tel"
            style="margin-right: 10px; height: 32px;">
        </el-input> {{ $t('UI.statusContent.UAH') }}
      </el-form-item>
      <el-form-item v-else :label="$t('pages.logbook.form.cost')" prop="price" required>
        <el-input
            v-model="service.price"
            :max="1000000"
            class="price-c"
            v-maska
            data-maska="0.99"
            data-maska-tokens="0:\d:multiple|9:\d:optional"
            placeholder="0.00"
            type="tel"
            style="margin-right: 10px; height: 32px;">
        </el-input> {{ $t('UI.statusContent.UAH') }}
      </el-form-item>
    </div>


    <el-form-item class="wysiwyg-quill" :label="$t('pages.logbook.form.desc')" prop="description">
      <quill-editor
          ref="serviceEditorEditor"
          v-model:content="service.description"
          contentType="html"
          toolbar="essential"/>
    </el-form-item>

    <el-form-item>
      <!-- :disabled="!services.length"-->
      <el-button size="small" @click="resetForm('service')">{{$t('UI.buttons.cancel')}}</el-button>
      <el-button size="small" type="danger" @click="submitForm('service')">{{$t('UI.buttons.save')}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {QuillEditor} from '@vueup/vue-quill'
import {mapState} from "vuex"
import { vMaska } from "maska"

export default {
  name: "AddService",
  components: {QuillEditor},
  directives: { maska: vMaska },
  props: {
    title: String,
    description: String,
    futureSevice: Boolean,
    postIsEmpty: Boolean,   //flag for check planing page or history - logbook
    postEdit:{
      type: Object,
      required: false
    }
  },
  data() {
    return {
      service: {
        id: '',
        title: '',
        date: '',
        price: '',
        mileage: '',
        description: '',
      },
      rules: {
        title: [
          {required: true, message: this.$t('pages.logbook.form.rules.title'), trigger: 'blur'},
          {min: 3, max: 100, message: this.$t('pages.logbook.form.rules.title-m'), trigger: 'blur'}
        ],
        mileage: [
          {required: true, message: this.$t('pages.logbook.form.rules.mileage'), trigger: 'blur'},
        ],
        price: [
          {required: true, message: this.$t('pages.logbook.form.rules.cost'), trigger: 'blur'},
        ],
        date: [
          {type: 'date', required: true, message: this.$t('pages.logbook.form.rules.date'), trigger: 'change'}
        ],
        description: [
          {required: true, message: this.$t('pages.logbook.form.rules.desc'), trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if(this.editServiceStatus === true){
            //копирует обьект и уберает реактивность
            const data = Object.assign({}, this.service)
            this.$store.dispatch('services/editService', {service: data, mess: this.$t('pages.logbook.notification.fuel-change')})
          }else{
            //копирует обьект и уберает реактивность
            const data = Object.assign({}, this.service)
            this.$store.dispatch('services/addService', {service: data, mess: this.$t('pages.logbook.notification.fuel-save')})
          }

          this.resetForm('service')
          this.$refs.serviceEditorEditor.pasteHTML('')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();

      if(this.editServiceStatus === true){
        this.$store.commit('services/setEditServiceStatus', false)
      }else{
        this.$store.commit('services/changeOpenAddForm', false)
      }
    },
  },
  computed:{
    ...mapState({
      editServiceStatus: state => state.services.editServiceStatus,
      car: state => state.car.carObject,
      services: state => state.services.services,
    }),
  },
  mounted() {
    if(this.postEdit){
      this.service.id = this.postEdit.id
      this.service.title = this.postEdit.title
      this.service.date = this.postEdit.date
      this.service.price = this.postEdit.price
      this.service.mileage = this.postEdit.mileage
      this.$refs.serviceEditorEditor.pasteHTML(this.postEdit.description)

      //this.car.yearOfIssue = new Date('01.01.' + this.carEdit.yearOfIssue);
    }else{
      this.service.mileage = this.car.acf.mileage
    }
  }
}
</script>

<style lang="scss" scoped>
.price-c{
  width: 150px;
}
</style>