<template>
  <el-button
      type="danger"
      :icon="Delete"
      size="small"
      class="el-button-mob"
      @click="open"
  ><el-icon><DeleteFilled /></el-icon>
    {{ $t('UI.buttons.delete') }}
  </el-button>
</template>

<script>
export default {
  name: "massege-confirm",
  props: {
    component: {
      type: Object,
      reserved: true
    }
  },
  methods: {
    open() {
      this.$confirm(this.$t('pages.components.notification.comp-delete.ask') + this.component.name + '?', 'Warning', {
        confirmButtonText: this.$t('pages.components.notification.comp-delete.delete'),
        cancelButtonText: this.$t('pages.components.notification.comp-delete.cancel'),
        type: 'warning'
      }).then(() => {
        this.$emit('remove', this.component)
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Delete canceled'
        // });
      });
    }
  }
}
</script>

<style lang="scss">
.el-message-box{
  font-family: 'Exo 2', sans-serif;
  p{
    line-height: 17px !important;
  }
}
.el-message{
  font-family: 'Exo 2', sans-serif;
}
</style>