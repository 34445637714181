import axios from "axios";
import i18n from '@/i18n/i18n'
import {dataLog} from "@/helpers/main";


export const services = {
  state: () => ({
    services: '',
    loading: true,
    editServiceStatus: false,
    openAddForm: false,
    categoryPostID: '',
    allPriceSev: null,
    allServices: []
  }),
  getters: {
    getCurrentCarID(state, _, rootState) {
      return rootState.car.currentCar;
    },
  },
  mutations: {
    setLoading(state, flag){
      state.loading = flag
    },
    setServices(state, services) {
      state.services = services;
    },
    setAllServices(state, services) {
      state.allServices = services;
    },
    addServices(state, service) {
      state.services.unshift(service);
    },
    resetServices(state) {
      state.services = '';
      state.allPriceSev = null;
    },
    changeOpenAddForm(state, status) {
      if(status === false){
        state.openAddForm = false;
      }else{
        state.openAddForm = !state.openAddForm;
      }
    },
    setEditServiceStatus(state, editServiceStatus) {
      console.log('reset')
      state.editServiceStatus = editServiceStatus;
    },
    setCategoryPost(state, catID) {
      state.categoryPostID = catID;
    },
    setAllPriceSev(state, price) {
      state.allPriceSev = price
    },
    cleanAllPriceServ(state) {
      state.allPriceSev = 0
    }
  },
  actions: {
    //for get posts by category
    async fetchService({state, commit, dispatch, getters}){
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/posts?categories=' + state.categoryPostID + '&car_id=' + getters.getCurrentCarID,
        });
        const data = Object.keys(response.data).map(key => ({id: response.data[key].id, ...response.data[key].acf}));
        dataLog(data, 'get posts');

        commit('setServices', data)
        dispatch('allPriceService')
        state.loading = false
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async fetchAllService({state, commit, dispatch, getters}){
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/posts?car_id=' + getters.getCurrentCarID,
        });
        let data = Object.keys(response.data).map(key => ({id: response.data[key].id, cat: response.data[key].categories, ...response.data[key].acf}));

        //filter not planing record
        data = data.filter(i => !i.cat.includes(4))

        dataLog(data, 'get service all');

        let price = 0
        data.map((i) => {
          price += +i.price
        })
        commit('setAllPriceSev', price)
        commit('setAllServices', data)
        state.loading = false
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async addService({state, dispatch, commit, getters}, {service, mess}){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/posts',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            title: `${service.title} ${service.mileage} км`,
            status: 'publish',
            categories: state.categoryPostID,
            acf: {
              "title": service.title,
              "mileage": service.mileage,
              "date": service.date,
              "price": service.price,
              "description": service.description,
              "car_id": getters.getCurrentCarID
            },
          })
        });
        const data = service
        data.id = response.data.id
        //state update
        commit('addServices', data)

        commit('setMessage', {type: 'success', text: mess}, {root: true})
        dispatch('allPriceService')
        //dispatch('fetchService')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async editService({dispatch, state, commit}, {service, mess}){
      //state update
      const i = state.services.findIndex(s => s.id === service.id)
      state.services[i] = service
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/posts/' + service.id,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            title: `${service.title} ${service.mileage} км`,
            acf: {
              "title": service.title,
              "mileage": service.mileage,
              "date": service.date,
              "price": service.price,
              "description": service.description,
            },
          })
        });
        dataLog(response.data, 'editServise')

        commit('setMessage', {type: 'success', text: mess}, {root: true})
        dispatch('allPriceService')
        //dispatch('fetchService')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async moveService({state, dispatch, commit}, {service, catID, mess}){
      //state update
      const i = state.services.findIndex(s => s.id === service.id)
      state.services[i] = service
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/posts/' + service.id,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            categories: catID
          })
        });
        dataLog(response.data, 'editServise')

        commit('setMessage', {type: 'warning', text: mess}, {root: true})
        //dispatch('fetchService')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async removeService({dispatch, state, commit}, {id}){
      //state update
      commit('setServices', state.services.filter(s => s.id !== id))
      try {
        const response = await axios({
          method: 'DELETE',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/posts/' + id,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          }
        });
        dataLog(response.data, 'deletePost')

        commit('setMessage', {type: 'error', text: i18n.global.t('store.delete-record')}, {root: true})
        dispatch('allPriceService')
        //dispatch('fetchService')
      } catch (error) {
        console.error(error);
        dispatch('action403', error, {root: true})
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async allPriceService({state, commit}){
      let price = 0
      state.services.map((i) => {
        price += +i.price
      })
      commit('setAllPriceSev', price)
    }
  },
  namespaced: true
}