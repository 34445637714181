<template>
	<div class="wrapper">
		<div class="empty_loyout">
			<router-view />
      <language-switch/>
		</div>

  </div>
</template>

<script>
import LanguageSwitch from "@/components/app/molecules/LanguageSwitch";
export default {
  components: {LanguageSwitch}
}
</script>

<style lang="scss" scoped>
.wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	background: #d4e8ea;
	width: 100%;
	height: 100vh;
}
.empty_loyout{
	max-width: 403px;
  margin-top: -65px;
	background: #fff;
	border-radius: 12px;
	width: 90%;
	box-shadow: 0px 10px 20px #00000017;
	&>div{
		padding: 60px 50px 80px;
    @media screen and (max-width: 768px) {
      padding: 50px 20px 60px;
    }
  }
}
</style>