<template>
  <div class="car_wrap">
    <div>
      <div class="line_car_wrap">
        <div>
          <h1>{{ title }}</h1>
          <div class="line_car">
            <span class="mileage">
              <div>
                <el-icon style="color: #0B626A"><Guide /></el-icon> <span @click="flagPopupEditMillage = !flagPopupEditMillage">{{ cars.mileage }} {{ $t('UI.statusContent.km') }}</span>
                <el-tooltip class="item" effect="dark" :content="$t('pages.main.tooltip.millage')" placement="bottom">
                  <el-icon
                      id="info"
                      style="color: #02B7BB; margin-left: 3px"><InfoFilled /></el-icon>
                </el-tooltip>
              </div>
              <edit-millage  :millage="cars.mileage" :flagPopupEditMillage="flagPopupEditMillage"/>

              <el-tooltip v-if="$isMobile() && cars.vin" class="item" effect="dark" :content="$t('pages.main.tooltip.vin')" placement="top">
                <span class="vin" @click="copyVinCode()" style="cursor: pointer">{{ cars.vin }}</span>
              </el-tooltip>
            </span>
          </div>
        </div>
        <el-tooltip v-if="!$isMobile() && cars.vin" class="item" effect="dark" :content="$t('pages.main.tooltip.vin')" placement="top">
          <span class="vin" @click="copyVinCode()" style="cursor: pointer">{{ cars.vin }}</span>
        </el-tooltip>
        <input type="hidden" id="copy-vincode" :value="cars.vin">

        <el-button class="btn-cust edit-car-btn" @click="$emit('edit')" type="primary" style="background: #0082A4; border: none"><el-icon style="margin-right: 5px"><EditPen /></el-icon>{{$t('UI.buttons.edit')}}</el-button>
        <el-button
            @click="deleteCar"
            :icon="Delete"
            style="margin-left: 5px; padding: 8px"
            type="danger"><el-icon><DeleteFilled /></el-icon></el-button>
      </div>

      <div v-html="cars.aboutCar" class="about_car"></div>
    </div>

    <Gallery/>
  </div>
</template>

<script>
import Gallery from "@/components/app/UI/Gallery";
import EditMillage from "@/components/carComponents/EditMillage";
export default {
  name: "Car",
  components: {EditMillage, Gallery},
  props:{
    cars:{
      type: Object,
      required: true
    }
  },
  data: () => ({
    flagPopupEditMillage: false
  }),
  methods:{
    deleteCar(){
      this.$confirm(this.$t('pages.main.delete-car') + this.title + '?', 'Warning', {
        confirmButtonText: this.$t('UI.yes'),
        cancelButtonText: this.$t('UI.no'),
        type: 'error'
      }).then(() => {
        const carID = this.$store.state.car.currentCar
        this.$store.dispatch('car/removeCar', {carID: carID, mess: this.$t('pages.main.notification.car-delete')})
      }).catch(() => {

      });
    },
    copyVinCode () {
      let textToCopy = document.querySelector('#copy-vincode')
      textToCopy.setAttribute('type', 'text')
      textToCopy.select()

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? this.$t('pages.main.notification.vin-copy') : this.$t('pages.main.notification.vin-no-copy')

        this.$store.commit('setMessage', {type: '', text: msg})
      } catch (err) {
        alert('Oops, unable to copy');
      }
      /* unselect the range */
      textToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
  mounted() {

  },
  computed:{
    title(){
      return `${this.cars.brand} ${this.cars.model}, ${this.cars.yearOfIssue}`
    }
  }
}
</script>

<style lang="scss" scoped>
  h1{
    font-size: 20px;
    margin-bottom: 0;
    line-height: 110%;
  }
  h2{
    font-size: 14px;
    margin-bottom: 3px;
  }
  .line_car_wrap{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &>div{
      flex: 1;
    }
  }
  .line_car{
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
  .mileage{
    >div{
      font-size: 12px;
      display: flex;
      cursor: pointer;
      transition: 0.3s;
      font-weight: bold;

      &:hover{
        color: #005a87;
      }
    }
    .el-icon{
      font-size: 16px;
      color: blue;
      margin-right: 3px;
    }
  }
  .vin{
    font-size: 12px;
    font-weight: bold;
    position: relative;
    margin-right: 10px;
    &:before{
      content: "VIN";
      font-size: 9px;
      color: #ff2525;
      position: absolute;
      left: -33px;
      top: -2px;
      border: 2px solid #FF2525;
      border-radius: 5px;
      padding: 1px 3px;
      font-weight: 600;
    }
  }
  .car_wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  #info{
    animation: pulse 2.5s linear infinite;
    cursor: auto;
    transition: 0.3s ease;
    &:hover{
      animation: paused;
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
</style>

<style lang="scss">
.mobile-app{
  .edit-car-btn{
    padding: 8px;
    span{
      font-size: 0;
      i{
        font-size: 14px;
        margin-right: 0 !important;
      }
    }
  }
  .vin{
    padding-left: 32px;
    margin-top: 7px;
    display: inline-block;
    &:before{
      left: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .main h1{
    font-size: 18px;
    margin-bottom: 5px;
  }
}
</style>

<style lang="scss">
.about_car{
  font-size: 13px;
  max-width: 500px;
  ul{
    list-style: none;
    padding-left: 12px;
    margin-top: 6px;
    li{
      position: relative;
      &:before{
        content: '-';
        position: absolute;
        left: -11px;
        top: -5px;
        font-size: 18px;
      }
    }
  }
}
</style>