<template>
  <!-- key - для обновлениея локали на лету -->
	<component :is="layout" :key="$i18n.locale"></component>

  <Notifications />
</template>

<script>

//TODO: components - тсранные сохранениея во время редактирования
//TODO: report - странные сохранение PDF мобила


//TODO: Add calc
import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import Notifications from "@/components/app/UI/Notifications";

export default {
  data: () => ({
    //loadApp: false
  }),
	computed: {
		layout(){
			return (this.$route.meta.layout || 'empty') + 'Layout'
		}
	},
	components: {
    Notifications,
		MainLayout, EmptyLayout
	}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,600&family=Oswald:wght@200;300;400;500;600;700&display=swap');
/*font-family: 'Exo 2', sans-serif; 700, 400, 600
font-family: 'Mulish', sans-serif;
font-family: 'Oswald', sans-serif;*/
//https://fonts.google.com/share?selection.family=Exo%202:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900%7CMulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,600%7COswald:wght@200;300;400;500;600;700


  *{
    box-sizing: border-box;
  }
  body{
    font-family: 'Exo 2', sans-serif;
  }
  #app {
    font-family: 'Exo 2', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    > *{
      font-family: 'Exo 2', sans-serif;
    }
  }
  a{
    text-decoration: none;
    color: #000;
  }
  h1{
    margin-top: 0;
    line-height: 36px;
    font-size: 24px;
  }
  p{
    font-size: 12px;
  }
  body{
    margin: 0;
  }
  .col-2{
    display: flex;
    margin: 0 -10px;
    &>div{
      flex: 1 1 100%;
      padding: 0 10px;
    }
    &.width-auto{
      >div{
        flex: none;
      }
    }
  }
  .col-3{
    display: flex;
    margin: 0 -10px;
    &>div{
      flex: 1 1 33.33%;
      padding: 0 10px;
    }
  }
  button.el-button.btn-cust{
    transition: 0.3s ease;
    &:hover{
      transform: scale(1.01);
    }
  }
  button.el-button.btn-opacity{
    transition: 0.3s ease;
    &:hover{
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 550px) {
    .main .col-2{
      display: flex;
      margin: 0;
      flex-direction: column;
      &>div{
        flex: 1 1 100%;
        padding: 0;
      }
    }
  }
  .el-input-number{
    height: 32px;
    input{
      height: 32px;
    }
    &.el-input-number--small{
      height: inherit;
    }
  }
  .el-input--small{
    input{
      height: 22px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
