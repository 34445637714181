<!--TODO - make update millage when add new services-->
<template>
  <div class="logbook" v-loading="loading">
    <div v-if="editServiceStatus === false">
      <AddService
          :title="$t('pages.logbook.title-logbook')"
          :description="$t('pages.logbook.desc-logbook')"
          :postIsEmpty="true"
          v-if="openAddForm == true"/>
          <!--v-if="Object.keys(services).length <= 0 || openAddForm == true"-->

      <ListLogbook
          v-else
          :title="$t('pages.logbook.title-logbook')"
          :description="$t('pages.logbook.desc-logbook')"
          :services = services
          @edit="editService"
      />
    </div>

    <AddService
        v-else
        :title="$t('pages.logbook.edit-note')"
        @cancel="setEditCarStatus(false)"
        :postEdit="editPost"/>
  </div>
</template>

<script>
import ListLogbook from "@/components/logBook/ListLogbook";
import AddService from "@/components/logBook/AddService";
import {mapState} from "vuex";

export default {
  name: 'Logbook',
  data: () => ({
    editPost: {},
    // 3 - id category Work from the car
    catID: 3
  }),
  components: {
    AddService,
    ListLogbook
  },
  computed:{
    ...mapState({
      services: state => state.services.services,
      loading: state => state.services.loading,
      editServiceStatus: state => state.services.editServiceStatus,
      openAddForm: state => state.services.openAddForm,
    }),
  },
  async created() {
    //this.$store.commit('services/setLoading', true)
    await this.$store.commit('services/setCategoryPost', this.catID)

    await this.$store.dispatch('services/fetchService', this.catID)
    //this.$store.dispatch('car/fetchCars')
    //this.services = this.$store.state.services.services

    this.$store.commit('services/setEditServiceStatus', false)
    this.$store.commit('services/changeOpenAddForm', false)
  },
  methods:{
    editService(post){
      this.editPost = post
      this.$store.commit('services/setEditServiceStatus', true)
    }
  },
  watch:{},
}
</script>

<style lang="scss">
.logbook.el-loading-parent--relative{
  height: 100%;
  .el-loading-mask{
    background: #ffffff;
  }
}
</style>