import axios from "axios";
import i18n from '@/i18n/i18n'

export const fuel = {
  state: () => ({
    fuels: '',
    start_point_fuel : '',
    loading: true
  }),
  getters: {
    getCurrentCar(state, _, rootState) {
      return rootState.car.currentCar;
    },
  },
  mutations: {
    setLoading(state, flag){
      state.loading = flag
    },
    setFuels(state, fuels) {
      state.fuels = fuels;
    },
    resetFuels(state) {
      state.fuels = '';
    },
  },
  actions: {
    async fetchFuels({state, commit, getters}){
      if(getters.getCurrentCar === null) return false

      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + getters.getCurrentCar,
        })

        if(response.data.acf.fuels !== null){
          const data = response.data.acf.fuels.map(i => {
            i.mileage = i.mileage_f
            return i
          })
          commit('setFuels', data)
        }else{
          commit('setFuels', [])
        }

        state.start_point_fuel = response.data.acf.start_point_fuel
        state.loading = false
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },
    addFuels({state, dispatch}, fuel){
      state.fuels.unshift(fuel)
      dispatch('updateFuels', {fuel: state.fuels, mess: i18n.global.t('store.add-record'), updateMillage: true})
    },
    editFuels({state, dispatch}, fuel){
      const i = state.fuels.findIndex(c => c.id === fuel.id)
      state.fuels[i] = fuel;
      dispatch('updateFuels', {fuel: state.fuels, mess: i18n.global.t('store.edit-record')})
    },
    removeFuels({state, dispatch}, fuel){
      state.fuels = state.fuels.filter( c => c.id !== fuel.id)
      dispatch('updateFuels', {fuel: state.fuels, mess: i18n.global.t('store.delete-record')})
    },
    async updateFuels({state, commit, dispatch, getters}, {fuel, mess}){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + getters.getCurrentCar,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            acf: {
              fuels: fuel
            }
          })
        })
        commit('setMessage', {type: 'success', text: mess}, {root: true})
        if(response.data.acf.fuels !== null){
          commit('setFuels', response.data.acf.fuels)
        }else{
          commit('setFuels', [])
        }
        //commit('setFuels', response.data.acf.fuels)
      } catch (error) {
        console.error(error);
        dispatch('action403', error, {root: true})
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },
    async setFuelPoint({state, commit, getters, dispatch}, id){
      try {
        state.start_point_fuel = id
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + getters.getCurrentCar,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            acf: {
              start_point_fuel: id
            }
          })
        })
        commit('setMessage', {type: 'success', text: i18n.global.t('store.save')}, {root: true})
      } catch (error) {
        console.error(error);
        dispatch('action403', error, {root: true})
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },
  },
  namespaced: true
}