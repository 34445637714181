<template>
  <div class="home" v-loading="loading">
    <AddCar
        v-if="this.$router.currentRoute.value.hash == '#addCar'"
        :title="$t('UI.add-car')"
        :style="loading ? { opacity: 0 } : {} "/>

    <div v-else-if="editCarStatus === false">
      <Car
          v-if="Object.keys(carObject).length"
          @edit="setEditCarStatus(true)"
          :cars="carObject.acf" />

      <AddCar
          v-else
          :title="$t('UI.add-car')"
          :style="loading ? { opacity: 0 } : {} "/>
    </div>

    <AddCar
        v-else
        :title="$t('UI.edit-car')"
        @cancel="setEditCarStatus(false)"
        :carEdit="carObject.acf"/>
  </div>
</template>

<script>
import Car from "@/components/car/Car";
import AddCar from "@/components/car/AddCar";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Home',
  components: {
    AddCar,
    Car
  },
  data: () => ({

  }),
  computed:{
    ...mapState({
      cars: state => state.car.cars,
      carObject: state => state.car.carObject,
      loading: state => state.car.loading,
      editCarStatus: state => state.car.editCarStatus,
    }),
  },
  created() {
    this.$store.commit('car/setEditCarStatus', false)
  },
  watch:{
    carObject(){
      this.$store.dispatch('services/fetchAllService')
    }
  },
  async mounted() {
    await this.$store.dispatch('services/fetchAllService')
  },
  methods:{
    ...mapMutations({
      setEditCarStatus: 'car/setEditCarStatus',
    }),
  },
}
</script>

<style lang="scss" scoped>
  .home{
    height: 100%;
  }
</style>