<template>
  <div>
    <div v-if="fuels.length">
      <el-table
          :data="fuels"
          style="width: 100%; margin-top: 20px">
        <!-- :row-class-name="tableRowClassName"     -->
        <el-table-column
            prop="mileage_f"
            :label="$t('pages.fuel.table.mileage')"
            width="100">
          <template v-slot="item">
            {{ item.row.mileage_f }} {{ $t('UI.statusContent.km') }}
          </template>
        </el-table-column>
        <el-table-column
            prop="date"
            :label="$t('pages.fuel.table.date')"
            width="100">
          <template v-slot="item">
            {{ getDate(item.row.date) }}

          </template>
        </el-table-column>

        <el-table-column
            prop="liters"
            :label="$t('pages.fuel.table.liters')"
            width="80">
        </el-table-column>

        <el-table-column
            prop="price"
            :label="$t('pages.fuel.table.cost')"
            width="100">
        </el-table-column>

        <el-table-column
            prop="fullTank"
            :label="$t('pages.fuel.table.full-tank')"
            width="80"
            align="center"
            style="font-size: 10px;"
        >
          <template v-slot="item">
            <img v-if="item.row.fullTank !== true" src="../../assets/image/fuel.png" alt="" style="width: 18px">

            <span v-else-if="item.row.id == this.fuels[0].id">
            <el-icon v-if="item.row.fullTank === true" style="cursor: no-drop"><Check style="color: #B0080A" /></el-icon>
          </span>

            <el-tooltip v-else class="item" effect="dark" :content="$t('pages.fuel.form.full-tooltip')" placement="left">
              <el-icon v-if="item.row.fullTank === true" @click="setFuelPoint(item.row)" style="cursor: pointer"><Check /></el-icon>
            </el-tooltip>

          </template>
        </el-table-column>

        <el-table-column
            prop="description"
            :label="$t('pages.fuel.table.comment')"
            width="260"
            style="font-size: 10px;"
        >
        </el-table-column>

        <el-table-column
            label=""
            align="right"
            width="210">
          <template v-slot="item">
            <el-tooltip v-if="item.row.id === start_point_fuel" class="item" effect="dark" :content="$t('pages.fuel.form.full-tank-tooltip')" placement="left">
              <img v-if="item.row.fullTank === true" src="../../assets/image/tank.png" alt="" style="width: 18px; margin: 0 5px -5px;">
            </el-tooltip>
            <el-button
                size="small"
                @click="handleEdit(item.row)">{{ $t('UI.buttons.edit-s') }}</el-button>
            <el-button
                size="small"
                type="danger"
                @click="handleDelete(item.$index, item.row)">{{ $t('UI.buttons.delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <p style="font-size: 11px;">{{ $t('pages.fuel.comment') }}</p>
      <p style="font-size: 11px;">{{ $t('pages.fuel.comment-1') }}</p>
    </div>

    <p v-else style="margin-top: 25px;">{{ $t('pages.fuel.no-fuels') }}</p>
  </div>

</template>

<script>
import MessageConfirm from "@/components/app/UI/MessageConfirm";
import {mapState} from "vuex";

export default {
  name: "listFuels",
  components: {MessageConfirm},
  props:{
    fuels:{
      type: Array,
      required: true
    }
  },
  data: () => ({

  }),
  methods: {
    handleEdit(row) {
      this.$emit('editFuel', row)
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('pages.fuel.notification.fuel-delete.ask') + row.mileage_f + ' ' + this.$t('UI.statusContent.km') + ' - '+ this.getDate(row.date) +'?', 'Warning', {
        confirmButtonText: this.$t('pages.fuel.notification.fuel-delete.delete'),
        cancelButtonText: this.$t('pages.fuel.notification.fuel-delete.cancel'),
        type: 'warning'
      }).then(() => {
        //сброс точки отсчета при удалении ее заправки
        if(row.id === this.start_point_fuel) this.$store.dispatch('fuel/setFuelPoint', '')
        this.$emit('remove', row)
      }).catch(() => {

      });
    },
    getDate( d ){
      const date = new Date(d);
      return date.toLocaleString('ru', {day: 'numeric', month: 'numeric', year: 'numeric'})
    },
    setFuelPoint(i){
      if(i.id !== this.start_point_fuel) this.$store.dispatch('fuel/setFuelPoint', i.id)
    },
    // tableRowClassName({row, rowIndex}) {
    //   console.log(rowIndex, this.a, row)
    //   if (rowIndex <= this.a) {
    //     return 'warning-row';
    //   }
    //   return '';
    // }
  },
  watch:{
    // fuels(){
    //   const a = this.fuels.findIndex(i => {
    //     return i.id === this.start_point_fuel
    //   })
    //   this.a = a
    // }
  },
  computed:{
    ...mapState({
      start_point_fuel: state => state.fuel.start_point_fuel
    })
  },
  mounted() {

  }
}
</script>


<style lang="scss">
//.warning-row{
//  background: #fafafa !important;
//}
.fuel{
  .el-table__header{
    font-size: 12px;
  }
  table{
    font-size: 13px;
  }
}
</style>