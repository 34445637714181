import { createStore } from 'vuex'
import router from "@/router";

import {authPhone} from "@/store/authPhone";
import {car} from "@/store/car";
import {components} from "@/store/components";
import {services} from "@/store/services";
import {fuel} from "@/store/fuel";
import {info} from "@/store/info";
import {auth} from "@/store/auth";

export default createStore({
  state: {
    currentUserID: null,
    menuFlag: false,
    //app notification
    error: null,
    message: ''
  },
  getters: {
    error: state => state.error,
    message: state => state.message,
  },
  mutations: {
    setCurrentUserID(state, userID){
      state.currentUserID = userID
    },
    resetCurrentUserID(state, userID){
      state.currentUserID = null
    },
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = null
    },
    setMessage(state, message) {
      state.message = '';
      state.message = message;
    },
    setMenuFlag(state, status) {
      state.menuFlag = status;
    }
  },
  actions: {
    resetStoreData({commit}, allReset = false){
      //reset store data
      if(allReset === true){
        commit('info/resetInfoData', null, {root: true})
        commit('car/resetCarData', null, {root: true})
        commit('resetCurrentUserID')
        commit('authPhone/resetState', null, {root: true})
        localStorage.removeItem('jwt')
      }

      commit('setMenuFlag', false)
      commit('comp/resetComponents', null, {root: true})
      commit('services/resetServices', null, {root: true})
      commit('fuel/resetFuels', null, {root: true})
    },
    action403({dispatch}, error){
      if(error.response.status == 403){
        dispatch('authPhone/logout', null, {root:true})
        router.push('/login')
      }
    }
  },
  modules: {
		authPhone: authPhone,
    car: car,
    comp: components,
    services: services,
    fuel: fuel,
    info: info,
    auth: auth,
  }
})


// async jwtLogin(){
//   try {
//     const response = await axios({
//       method: 'POST',
//       url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/jwt-auth/v1/token',
//       data: {
//         username: 'admin',
//         password: 'mz8ka3ehq4'
//       },
//     });
//     localStorage.setItem('jwt', response.data.token)
//     console.log(response.data.token, 'jwt');
//   } catch (error) {
//     console.error(error);
//   }
// }