import axios from "axios";
import router from '@/router'
import {dataLog} from '@/helpers/main'

export const car = {
  state: () => ({
    cars: '',
    loading: true,
    carObject: '',
    carPhoto: {urls: [],imageData: []},
    editCarStatus: false,
    countCarMillage: null,
    currentCar: null,
  }),
  getters: {
    getCurrentUserID(state, _, rootState) {
      return rootState.currentUserID;
    },
  },
  mutations: {
    setCars(state, cars) {
      state.cars = cars;
    },
    setCarCurrentObject(state) {
      state.carObject = state.cars.filter( cars => cars.id === state.currentCar)[0];
    },
    setCurrentCar(state, carID) {
      state.currentCar = carID;
    },
    setEditCarStatus(state, editCarStatus) {
      state.editCarStatus = editCarStatus;
    },
    setCountCarMillage(state){
      state.countCarMillage = state.carObject.acf.mileage - state.carObject.acf.mileageBuy
    },
    resetCarData(state){
      state.cars = ''
      state.carObject = ''
      state.countCarMillage = null
      state.currentCar = null
      state.carPhoto = {urls: [], imageData: []}
    },
    resetCarPhoto(state){
      state.carPhoto = {urls: [],imageData: []}
    }
  },
  actions: {
    async fetchCars({state, commit, getters, dispatch}){
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars?author=' + getters.getCurrentUserID,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
        });

        dataLog(response.data, 'get cars');
        commit('setCars', response.data)

        // if(state.currentCar === null){
        //   state.currentCar = state.cars[0].id
        //   commit('setCarCurrentObject')
        //   commit('setCountCarMillage')
        // }
        state.loading = false
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async fetchCar({state, commit, dispatch}, carID){
      if(carID === null) return false

      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + carID,
        });

        dataLog(response.data, 'get car');
        state.carObject = response.data
        if(response.data.acf.photos !== null){
          await dispatch('loadImage', response.data.acf.photos)
        }

        commit('setCountCarMillage')
        state.loading = false
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async addCar({state, dispatch, commit}, {car, mess}){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            title: `${car.brand} ${car.model} ${car.yearOfIssue}`,
            status: 'publish',
            acf: {
              "brand": car.brand,
              "model": car.model,
              "vin": car.vin,
              "yearOfIssue":  car.yearOfIssue,
              "fuel": car.fuel,
              "mileage": car.mileage,
              "mileageBuy": car.mileageBuy,
              "aboutCar": car.aboutCar,
              "photos": car.photos,
            }
          })
        });
        const post = response.data
        dataLog(post, 'addCar')
        commit('setMessage', {type: 'success', text: mess}, {root: true})
        //dispatch('fetchCars')

        dispatch('setCurrentCar', post.id)
        dispatch('fetchCars')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async removeCar({state, dispatch, commit}, {carID, mess}){
      try {
        // delete car
        const responseCar = await axios({
          method: 'DELETE',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + carID,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
        });
        if(responseCar.status != 200){
          return false;
        }
        commit('setMessage', {type: 'error', text: mess}, {root: true})

        dataLog(responseCar.data, 'removeCar')
        await dispatch('removePosts', responseCar.data.id)

        //get rest cars
        const restCar = state.cars.filter( car => car.id !== carID)

        //TODO: will do without reload page
        if(restCar.length > 0){
          dispatch('setCurrentCar', restCar[0].id)
          window.location.replace(window.location.origin);
        }else{
          //deleting last page
          dispatch('info/removeCurrentCarWB', {}, {root: true})
          window.location.replace(window.location.origin);
        }
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async editCar({dispatch, state, commit}, {car, mess}){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + state.currentCar,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            title: `${car.brand} ${car.model} ${car.yearOfIssue}`,
            acf: {
              "brand": car.brand,
              "model": car.model,
              "vin": car.vin,
              "yearOfIssue":  car.yearOfIssue,
              "fuel": car.fuel,
              "mileage": car.mileage,
              "mileageBuy": car.mileageBuy,
              "aboutCar": car.aboutCar,
              "photos": (car.photos === null) ? [] : car.photos,
            }
          })
        });
        dataLog(response.data, 'editCar')
        commit('setMessage', {type: 'success', text: mess}, {root: true})

        commit('setEditCarStatus', false)
        commit('resetCarPhoto')
        dispatch('fetchCar', state.currentCar)
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async editMillage({dispatch, state, commit}, {millage, mess}) {
      try {
        state.carObject.acf.mileage = millage

        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/cars/' + state.currentCar,
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: JSON.stringify({
            acf: {
              "mileage": millage,
            }
          })
        });
        dataLog(response.data, 'editMillage')
        commit('setMessage', {type: '', text: mess}, {root: true})

        commit('setEditCarStatus', false)
        //dispatch('fetchCars')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    },

    async setCurrentCar({state, commit, dispatch}, idCar){
      try {
        //reset store data
        dispatch('resetStoreData', null, {root: true})
        state.carPhoto = {urls: [], imageData: []}

        await dispatch('info/updateInfo', {currentCar: idCar}, {root:true})
        await commit('setCurrentCar', idCar)
        await commit('setCarCurrentObject')

        await dispatch('fetchCar', idCar)
        //go to home page
        router.push('/')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
      }
    },

    async loadImage({state}, photos){
      photos.map(async p => {
        const photo = await axios({
          method: 'GET',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/media/' + p.photo,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
        });
        if(!state.carPhoto.urls.includes(photo.data.source_url)){
          state.carPhoto.urls.push(photo.data.source_url)
          state.carPhoto.imageData.push({id: photo.data.id, url: photo.data.source_url})
        }
      })
    },

    //delete post when car removed
    async removePosts({state, commit, dispatch}, carID){
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_DOMAIN_REST_API + '/wp-json/wp/v2/car/post-delete/',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
          },
          data: {carID: carID}
        });
        dataLog(response.data, 'postDelete')
      } catch (error) {
        console.error(error);
        commit('setMessage', {type: 'error', text: 'Oh, error: ' + error.message}, {root: true})
        dispatch('action403', error, {root: true})
      }
    }
  },
  namespaced: true
}